import { useAppContext } from "../context/AppContext";
import { useEffect } from "react";
export const useDividerAnimation = (animation: string) => {
  const { setSectionAnimation } = useAppContext();

  useEffect(() => {
    if (animation === "animation-on") {
      document.getElementById("main")?.classList.add("hide-all-bg");
      setSectionAnimation(true);
    } else {
      document.getElementById("main")?.classList.remove("hide-all-bg");
      setSectionAnimation(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return [];
};
