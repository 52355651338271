import { useState, useRef, useEffect, Dispatch, SetStateAction } from "react";
import { ImageType, VideoFileType } from "../../utils/baseTypes";

import { useInView } from "framer-motion";
import SoundOn from "../../images/Media/icon_sound_on.svg";
import SoundOff from "../../images/Media/icon_sound_off.svg";
import Play from "../../images/Media/play.svg";
import Pause from "../../images/Media/pause.svg";
import { iframeSrcFetch } from "../../utils/iframeSrcFetch";
import Image from "next/image";

export const fetchVideoThumbnail = (video: string) => {
  return new Promise<string>((resolve, reject) => {
    const srcUrl = video.match(/src="([^"]+)"/);
    if (!srcUrl?.[1]) {
      reject();
    }

    const src = srcUrl?.[1];

    fetch(`/api/fetchVideoThumbnail?url=${src}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const dataURL = data.videoThumbnail.dataURL;
        resolve(dataURL);
      })
      .catch((error) => {
        console.warn("could not fetch yt thumbnail:", error);
        reject();
      });
  });
};

export const Video = ({
  onlineVideo,
  poster,
  activeVideo = -1,
  setActiveVideo,
  index,
  videoType,
  onPlay = () => {},
}: {
  onlineVideo: string;
  poster?: ImageType;
  videoType?: "youtube" | "vimeo" | "iframe";
  activeVideo?: number;
  setActiveVideo?: Dispatch<SetStateAction<number>>;
  index?: number;
  onPlay?: Function;
}) => {
  const [preconnected, setPreconnected] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string>("");
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const realIndex = typeof index === "number" ? index : -1;

  useEffect(() => {
    if (realIndex !== activeVideo) {
      setShowVideo(false);
    }
    //eslint-disable-next-line
  }, [activeVideo]);

  useEffect(() => {
    if (showVideo) {
      onPlay();
    }
    //eslint-disable-next-line
  }, [showVideo]);

  useEffect(() => {
    poster?.url
      ? setImageURL(poster.url)
      : onlineVideo &&
        fetchVideoThumbnail(onlineVideo)
          .then((thumbnailDataUrl) => {
            setImageURL(thumbnailDataUrl);
          })
          .catch(() => {});

    //eslint-disable-next-line
  }, [onlineVideo]);

  const url = iframeSrcFetch(onlineVideo);

  const warmConnections = () => {
    if (preconnected) return;
    setPreconnected(true);
  };

  const videoJSX = (
    <div onPointerOver={warmConnections} className="iframe-box">
      <div
        className="poster"
        onClick={() => {
          setShowVideo(true);
          setActiveVideo
            ? realIndex > -1
              ? setActiveVideo(realIndex)
              : null
            : null;
        }}
        style={{
          backgroundImage: imageURL ? `url(${imageURL})` : "",
        }}
      >
        <Play />
      </div>
      {showVideo && (
        <iframe
          src={`${url}&autoplay=1`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      )}
    </div>
  );

  return (
    <>
      <link rel="preload" href={imageURL} as="image" />
      {videoType === "youtube" && preconnected && (
        <>
          <link rel="preconnect" href={url} />
          <link rel="preconnect" href="https://www.google.com" />
        </>
      )}

      {videoType === "vimeo" && preconnected && (
        <>
          <link rel="preconnect" href={url} />
          <link rel="preconnect" href="https://f.vimeocdn.com" />
          <link rel="preconnect" href="https://player.vimeo.com" />
          <link rel="preconnect" href="https://i.vimeocdn.com" />
        </>
      )}
      {videoJSX}
    </>
  );
};

export const InternalVideo = ({
  video,
  poster,
  autoplay,
  controls = false,
  loop = true,
  enableAudioToggle = false,
  onPlay = () => {},
  onPause = () => {},
}: {
  video: VideoFileType;
  poster?: ImageType;
  autoplay: boolean;
  controls?: boolean;
  loop?: boolean;
  enableAudioToggle: boolean;
  onPlay?: Function;
  onPause?: Function;
}) => {
  const options = {
    autoPlay: autoplay,
    muted: autoplay,
    controls: controls,
    loop: loop,
    src: video.url!,
  };

  const [isMute, toggleMute] = useState<boolean>(autoplay ?? true);
  const [playing, togglePlaying] = useState<boolean>(false);
  const [showPoster, setShowPoster] = useState<boolean>(true);

  const videoNode = useRef<HTMLVideoElement>(null);

  const progressBar = useRef<HTMLDivElement>(null);
  const inView = useInView(videoNode);

  const seek = (e: React.MouseEvent) => {
    if (videoNode?.current) {
      const video = videoNode.current;
      const element = e.currentTarget;
      const rect = element.getBoundingClientRect();
      const left = e.pageX - rect.left;
      const totalWidth = rect.width;
      const percentage = left / totalWidth;
      const vidTime = video.duration * percentage;
      video.currentTime = vidTime;
      setShowPoster(false);
    }
  };

  useEffect(() => {
    if (progressBar.current && videoNode.current) {
      const progressElem = progressBar.current.querySelector(
        "#progress"
      ) as HTMLSpanElement;
      const video = videoNode.current;
      video.ontimeupdate = () => {
        const percentage = (video.currentTime / video.duration) * 100;
        progressElem.style.width = `${percentage}%`;
      };
    }
  }, []);

  useEffect(() => {
    if (videoNode.current) {
      if (videoNode.current.readyState >= videoNode.current.HAVE_FUTURE_DATA) {
        if (autoplay) {
          setShowPoster(false);
        }
      }
    }
  }, [autoplay]);

  useEffect(() => {
    if (videoNode.current && autoplay) {
      if (inView) {
        videoNode.current.play();
      } else {
        videoNode.current.pause();
      }
    }
    //eslint-disable-next-line
  }, [inView]);

  useEffect(() => {
    if (videoNode.current) {
      videoNode.current.muted = isMute;
    }
  }, [isMute]);

  useEffect(() => {
    if (videoNode.current) {
      if (playing) {
        videoNode.current.muted = false;
        videoNode.current.play();
        onPlay();
      } else {
        videoNode.current.muted = true;
        videoNode.current.pause();
        onPause();
      }
    }

    //eslint-disable-next-line
  }, [playing]);

  const videoJSX = (
    <div className={`inline-video ${playing ? "media-paying" : ""}`}>
      {poster?.url && showPoster && (
        <Image
          src={poster.url}
          alt={poster.alt}
          width={poster.width}
          height={poster.height}
          className="inline-video-poster"
        />
      )}
      <video
        {...options}
        ref={videoNode}
        onCanPlayThrough={() => {
          if (autoplay) {
            setShowPoster(false);
          }
        }}
      />

      {!autoplay && (
        <>
          <div ref={progressBar} className="seek-bar" onClick={seek}>
            <span className="progress" id="progress"></span>
          </div>
          <div className="media-control">
            <div
              className="media-control-icons"
              onClick={() => {
                togglePlaying((last) => !last);
                setShowPoster(false);
              }}
            >
              {playing ? (
                <Pause width={150} height={150} />
              ) : (
                <Play width={150} height={150} />
              )}
            </div>
          </div>
        </>
      )}

      {enableAudioToggle && (
        <div className="sound-toggle">
          <button
            className="sound-toogle-button"
            onClick={() => toggleMute((last) => !last)}
          >
            {isMute ? (
              <SoundOff width={40} height={40} />
            ) : (
              <SoundOn width={40} height={40} />
            )}
          </button>
        </div>
      )}
    </div>
  );

  return <>{videoJSX}</>;
};
