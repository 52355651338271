import React from "react";
import { Section } from "../../Global/Section";
import { HeaderSeiteType, PageType } from "../../../utils/baseTypes";
import useAnimeKey from "../../../hooks/useAnimeKey";
import { motion } from "framer-motion";
import { stagger_variant } from "../../../utils/transition";
import {
  PageDivider,
  PageHeadline,
  PageContent,
  PageBreadcrumb,
} from "./Common";
import { CTABox } from "../../TextCTA/CTABox";
import { blur_opacity_variant } from "../../../utils/transition";
import { ScrollDown } from "../../ScrollDown/ScrollDown";

interface CTABoxHeaderProps {
  post: PageType;
  isSinglePage: boolean;
}

export const CTABoxHeader = ({ post, isSinglePage }: CTABoxHeaderProps) => {
  const header = post.acf?.header_seite as HeaderSeiteType;

  const {
    title,
    content,
    subline,
    background,
    divider,
    number_cols,
    hpos_class,
    tpos_class,
    cta_box,
    title_size,
  } = header;

  const animekey = useAnimeKey({ ext: "cta-box-header" });

  return (
    <>
      <section
        className={`cta-box-header section padding-top-0 divider ${background} fullscreen ${
          isSinglePage ? "is-single-page" : ""
        }`}
      >
        <PageBreadcrumb post={post} isSinglePage={isSinglePage} />
        <div className="container">
          <motion.div
            initial="initial"
            whileInView={"animate"}
            variants={stagger_variant}
            viewport={{ once: true }}
            key={animekey}
            className="columns is-multiline"
          >
            <PageDivider background={background} divider={divider} />
            <div className={`column is-12 is-6-desktop`}>
              <PageHeadline
                subline={subline}
                title={title}
                tpos_class={tpos_class}
                title_size={title_size}
              />

              {content && (
                <PageContent
                  hpos_class={hpos_class}
                  number_cols={number_cols}
                  tpos_class={tpos_class}
                  content={content}
                />
              )}
            </div>
            <motion.div
              variants={blur_opacity_variant}
              className={`column is-12 is-6-desktop`}
            >
              <CTABox cta={cta_box} />
            </motion.div>
          </motion.div>
        </div>
      </section>
      <ScrollDown background={background} className="is-hidden-touch" />
    </>
  );
};
