import { useRouter } from "next/router";
import { OptionsType } from "../../utils/baseTypes";
import { Section } from "../Global/Section";
import { SearchBar } from "../SearchBar";

interface SearchProps {
  options: OptionsType;
  classes?: string;
}

export const Search = ({ options, classes }: SearchProps) => {
  const headline = options.acf.search_headline;

  const router = useRouter();
  const searchHandler = (e: any) => {
    if (e.key === "Enter") {
      router.push("/search?keyword=" + e.target.value);
    }
  };

  return (
    <>
      <Section className={`wiki search ${classes}`}>
        <SearchBar headline={headline ?? ""} searchHandler={searchHandler} />
      </Section>
    </>
  );
};
