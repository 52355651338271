import { motion } from "framer-motion";
import { sanitize } from "isomorphic-dompurify";
import { useEffect, useState } from "react";
import { WikiType } from "../../utils/baseTypes";
import { Button } from "../Global/Button";
import Arrow from "../../images/arrow.svg";
import { unlinkWiki } from "../../utils/unlinkWiki";
import useWindowSize from "../../hooks/useWindowSize";
interface WikiCtaBoxProps {
  post: WikiType;
  fixedTop: boolean;
}

export const WikiCtaBox = ({ post, fixedTop }: WikiCtaBoxProps) => {
  const link = post.acf?.wiki_header?.link;
  const [hideCta, setHideCta] = useState(false);
  const { width } = useWindowSize();
  const isWideScreen = width < 1216;

  const transition_ = {
    ease: [0.455, 0.03, 0.515, 0.955],
    duration: 0.85,
  };

  const variant_right = {
    visible: {
      opacity: 1,
      x: 0,
      transition: transition_,
    },
    hidden: {
      opacity: 1,
      x: "101%",
      transition: transition_,
    },
  };
  useEffect(() => {
    if (isWideScreen) {
      setHideCta(true);
    } else {
      setHideCta(false);
    }
  }, [isWideScreen]);
  return (
    <motion.div
      variants={variant_right}
      initial="hidden"
      animate={hideCta ? "hidden" : "visible"}
      className={`wiki-cta-box has-gradient-bg ${fixedTop ? "fixed-top" : ""}`}
      // className={`cta-box has-gradient-bg  ${
      //   isTouch ? "fixed-top" : "is-sticky"
      // }`}
    >
      <div
        className="action-arrow has-gradient-bg"
        onClick={() => setHideCta((last) => !last)}
      >
        <Arrow width={22.06} height={14.58} />
      </div>
      <span className="h3 has-text-centered is-block">
        {unlinkWiki(post.acf?.wiki_header?.headline ?? "")}
      </span>
      {post.acf?.wiki_header?.cta_content && (
        <div
          className="content has-text-white"
          dangerouslySetInnerHTML={{
            __html: sanitize(post.acf.wiki_header.cta_content, {
              ADD_ATTR: ["target"],
            }),
          }}
        />
      )}
      {link && (
        <Button
          type="primary"
          title={link.title}
          target={link.target}
          href={link.url}
          className={"mx-auto my-5"}
        />
      )}
    </motion.div>
  );
};
