import { sanitize } from "isomorphic-dompurify";
import { HeaderStartseiteType, ImageType } from "../../../utils/baseTypes";
import useWindowSize from "../../../hooks/useWindowSize";
import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

interface HeroProps {
  post: HeaderStartseiteType;
}

import { motion } from "framer-motion";
import {
  blur_opacity_variant,
  defaultEase,
  full_y_variant,
} from "../../../utils/transition";
import Divider from "../../Divider/Divider";
import { useAnimeKeyWithDep } from "../../../hooks/useAnimeKey";
import { useRouter } from "next/router";
import { ScrollDown } from "../../ScrollDown/ScrollDown";
import { unlinkWiki } from "../../../utils/unlinkWiki";
import Image from "next/image";

export const stagger_variant = {
  animate: {
    transition: {
      ease: defaultEase,
      staggerChildren: 0.03,
    },
  },
};

export const Hero = ({ post }: HeroProps) => {
  const { words, content, divider, slogan, title, title_size } = post;

  const { asPath } = useRouter();
  const { width } = useWindowSize();
  const isDekstop = width > 1023;

  const [activeWord, setActiveWord] = useState<number>(isDekstop ? -1 : 0);
  const animeKey = useAnimeKeyWithDep({ ext: "hero", deps: [width, asPath] });

  useEffect(() => {
    isDekstop ? setActiveWord(-1) : setActiveWord(0);
  }, [isDekstop]);

  const wordOne = Array.isArray(words) ? words[0] : null;
  const wordTwo = Array.isArray(words) ? words[1] : null;
  const wordThree = Array.isArray(words) ? words[2] : null;

  const positionOne = wordOne;
  //? swithing the word position on tablet and mobile
  const positionTwo = isDekstop ? wordTwo : wordThree;
  const positionThree = isDekstop ? wordThree : wordTwo;

  // making an array of all the videos to use in mobile and tablet
  const videos = [positionOne, positionTwo, positionThree].map((x): string => {
    return x
      ? x.upload_video.video_mp4
        ? x.upload_video.video_mp4.url!
        : ""
      : "";
  });

  const videoPoster = post.words?.[0]?.upload_video?.poster;

  return (
    <>
      <section className="section startseite-header">
        <motion.div
          key={animeKey}
          whileInView={"animate"}
          initial={"initial"}
          variants={stagger_variant}
          viewport={{ once: true }}
          className="text-animation-wrapper"
        >
          <div className="columns is-multiline">
            {/* //! video to show on mobile */}
            {width < 824 && (
              <div className="column is-12 is-flex is-justify-content-center is-hidden-tablet">
                <motion.div className="mobile-video-wrapper">
                  <VideoPlayers
                    activeWord={activeWord}
                    setActiveWord={setActiveWord}
                    videos={videos}
                    videoPoster={videoPoster}
                  />
                </motion.div>
              </div>
            )}

            {/*  //! position one */}
            <div
              data-cursor-video={
                positionOne ? positionOne.upload_video.video_mp4.url : ""
              }
              className="column is-12-mobile is-7-tablet is-6-desktop  position-one "
            >
              {positionOne && (
                <TextBig
                  text={positionOne.text}
                  position={0}
                  activeWord={activeWord}
                />
              )}
            </div>

            {/* //! video to show on tablet */}
            {width < 1024 && width > 823 && (
              <motion.div
                variants={blur_opacity_variant}
                className="column is-5-tablet is-hidden-mobile is-hidden-desktop"
              >
                <div className="tablet-video-wrapper">
                  <VideoPlayers
                    activeWord={activeWord}
                    setActiveWord={setActiveWord}
                    videos={videos}
                    videoPoster={videoPoster}
                  />
                </div>
              </motion.div>
            )}

            {/*  //! position two */}
            <div
              data-cursor-video={
                positionTwo ? positionTwo.upload_video.video_mp4.url : ""
              }
              className="column is-12 is-6-desktop  position-two"
            >
              {positionTwo && (
                <TextBig
                  text={positionTwo.text}
                  position={1}
                  activeWord={activeWord}
                />
              )}
            </div>

            {/*  //! position three */}
            <div
              data-cursor-video={
                positionThree ? positionThree.upload_video.video_mp4.url : ""
              }
              className="column is-12 is-offset-2-desktop is-6-desktop position-three"
            >
              {positionThree && (
                <TextBig
                  text={positionThree.text}
                  position={2}
                  activeWord={activeWord}
                />
              )}
            </div>

            <div className="column is-4-desktop slogan-wrapper">
              <motion.span
                variants={blur_opacity_variant}
                className="slogan"
                dangerouslySetInnerHTML={{ __html: sanitize(slogan) }}
              ></motion.span>
            </div>
          </div>
        </motion.div>
        <div className="container">
          <div className="is-flex">
            <motion.div
              whileInView={"animate"}
              initial={"initial"}
              viewport={{ amount: 0.2, once: true }}
              variants={stagger_variant}
              className="columns is-multiline"
            >
              {divider && divider.divider_on && (
                <div className="column is-12">
                  <Divider data={divider} background={"has-white-bg"} />
                </div>
              )}
              <div className="column is-12 is-6-desktop">
                <motion.h1
                  variants={blur_opacity_variant}
                  className={title_size}
                >
                  <strong
                    dangerouslySetInnerHTML={{ __html: sanitize(title) }}
                  ></strong>
                </motion.h1>
              </div>
              <motion.div
                variants={blur_opacity_variant}
                className="column is-12 is-offset-1-desktop is-5-desktop"
                dangerouslySetInnerHTML={{ __html: sanitize(content) }}
              />
            </motion.div>
          </div>
        </div>
      </section>
      <ScrollDown background={"has-white-bg"} />
    </>
  );
};

const TextBig = ({
  text,
  activeWord,
  position,
}: {
  text: string;
  activeWord: number;
  position: number;
}) => {
  return (
    <motion.span
      variants={full_y_variant}
      className={`text-big ${activeWord === position ? "is-active" : ""}`}
    >
      {unlinkWiki(text)}
    </motion.span>
  );
};

interface IVideoPlayers {
  videos: string[];
  activeWord: number;
  setActiveWord: React.Dispatch<React.SetStateAction<number>>;
  videoPoster: ImageType | false;
}

const VideoPlayers = ({
  videos,
  activeWord,
  setActiveWord,
  videoPoster,
}: IVideoPlayers) => {
  // const [showPoster, setShowPoster] = useState<boolean>(true);
  const poster = videoPoster ? (videoPoster.url ? videoPoster.url : "") : "";
  return (
    <>
      <Image
        src={poster}
        alt="hero-poster"
        fill
        className="hero-poster"
        priority={true}
        sizes="(max-width: 768px) 80vw, 33vw"
        style={{ objectFit: "cover", objectPosition: "center" }}
      />

      {videos.map((video, i) => {
        return (
          <motion.div
            variants={blur_opacity_variant}
            initial="initial"
            animate={i === activeWord ? "animate" : "exit"}
            key={i}
            className="player-wrapper"
          >
            <ReactPlayer
              url={video}
              playsinline
              playing={i === activeWord}
              width={"100%"}
              height={"100%"}
              muted
              // onReady={() => {
              //   if (i === activeWord) {
              //     setShowPoster(false);
              //   }
              // }}
              config={{
                file: {
                  attributes: {
                    preload: "none",
                  },
                },
              }}
              onEnded={() => {
                setActiveWord((last) =>
                  last + 1 < videos.length ? last + 1 : 0
                );
              }}
            />
          </motion.div>
        );
      })}
    </>
  );
};
