import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { HeroPageProps } from ".";
import { PageBreadcrumb, PageCTA, PageContent, PageHeadline } from "../Common";
import { HeaderSeiteType } from "../../../../utils/baseTypes";
import {
  blur_opacity_variant,
  stagger_variant,
} from "../../../../utils/transition";
import useAnimeKey from "../../../../hooks/useAnimeKey";
import useWindowSize from "../../../../hooks/useWindowSize";
import Image from "next/image";
import { InternalVideo, Video } from "../../../Global/Video";
import { SvgIcon } from "../../../Global/SvgIcon";
import { getImageType, getVideoType } from "../../../../utils/responsiveFile";
import { VideoFrame } from "../VideoFrame";
interface HeroSizeScreenProps extends HeroPageProps {
  size?: "half" | "three-quarters";
}

export const HeroSizeScreen = ({
  post,
  isSinglePage,
  options,
  size = "half",
}: HeroSizeScreenProps) => {
  const header = post.acf?.header_seite as HeaderSeiteType;

  const animeKey = useAnimeKey({ ext: "hero-half-media" });

  const mediaType = header.hero_media.hm_type;

  const columnOne = size === "half" ? "is-12 is-6-desktop" : "";
  const columnTwo =
    size === "half" ? "is-12 is-6-desktop" : "is-12 is-two-thirds-desktop";

  const {
    background,
    header_height,
    with_borders,
    hero_media,

    title,
    content,
    subline,
    number_cols,
    tpos_class,
    hpos_class,
    link,
    title_size,
    button_label,
    cta_type,

    video_oblique,
  } = header;

  const { width } = useWindowSize();

  const activeImage = getImageType({
    width: width,
    images: {
      image: hero_media.hm_image,
      image_tablet: hero_media.hm_tablet,
      image_mobile: hero_media.hm_mobile,
    },
  });

  const activeVideo = getVideoType({
    videos: {
      video: hero_media.hm_video.hm_video,
      video_tablet: hero_media.hm_video.upload_video_tablet,
      video_mobile: hero_media.hm_video.upload_video_mobile,
    },
    width: width,
  });

  const isAnimation = hero_media.hm_svg_animation ? true : false;

  const [disableRotate, setDisableRotate] = React.useState<boolean>(false);

  return (
    <>
      <motion.section
        initial="initial"
        whileInView={"animate"}
        variants={stagger_variant}
        viewport={{ once: true }}
        key={animeKey}
        className={`hero-page ${background} ${header_height} ${
          isSinglePage ? "is-single-page" : ""
        } ${with_borders ? "border-on" : "border-off"} ${
          isAnimation ? "is-animation" : ""
        }`}
      >
        <div className={`frame ${with_borders ? "frame-on" : "frame-off"}`}>
          <div className="border">
            <div className="columns is-centered is-multiline main-columns">
              <div
                className={`column ${columnOne} is-flex is-flex-direction-column is-justify-content-center is-align-items-center`}
              >
                <div className="padding-6">
                  <PageHeadline
                    tpos_class={tpos_class}
                    subline={subline}
                    title={title}
                    title_size={title_size}
                  />
                  {content && (
                    <PageContent
                      hpos_class={hpos_class}
                      tpos_class={tpos_class}
                      number_cols={number_cols}
                      content={content}
                    />
                  )}
                  <PageCTA
                    link={link}
                    cta_type={cta_type}
                    button_label={button_label}
                    options={options}
                  />
                </div>
              </div>
              <motion.div
                variants={blur_opacity_variant}
                className={`column ${columnTwo} hero-media-wrapper   ${
                  video_oblique && mediaType === "video" ? "p-6 " : ""
                }  ${isAnimation ? "is-animation" : ""}`}
              >
                <VideoFrame
                  wrap={video_oblique && video_oblique && mediaType === "video"}
                  disableRotate={disableRotate}
                >
                  {mediaType === "image" && activeImage && (
                    <Image
                      src={activeImage.url ?? ""}
                      alt={activeImage.alt}
                      data-title={activeImage.title}
                      fill
                      style={{ objectFit: "cover" }}
                      priority={true}
                    />
                  )}

                  {mediaType === "video" && (
                    <>
                      {hero_media.hm_video.video_type === "upload" &&
                        activeVideo && (
                          <InternalVideo
                            autoplay={hero_media.hm_video.autoplay}
                            video={activeVideo}
                            enableAudioToggle={hero_media.hm_video.autoplay}
                            onPlay={() => {
                              setDisableRotate(true);
                            }}
                            onPause={() => {
                              if (!hero_media.hm_video.autoplay)
                                setDisableRotate(false);
                              else setDisableRotate(true);
                            }}
                            poster={
                              hero_media.hm_image
                                ? hero_media.hm_image
                                : undefined
                            }
                          />
                        )}
                      {hero_media.hm_video.video_type === "Youtube" && (
                        <Video
                          videoType={"youtube"}
                          onlineVideo={hero_media.hm_video.externes_video}
                          onPlay={() => setDisableRotate(true)}
                        />
                      )}
                      {hero_media.hm_video.video_type === "Vimeo" && (
                        <Video
                          videoType={"vimeo"}
                          onlineVideo={hero_media.hm_video.externes_video}
                          onPlay={() => setDisableRotate(true)}
                        />
                      )}
                    </>
                  )}

                  {mediaType === "svg" && (
                    <SvgIcon
                      svg={hero_media.hm_svg ? hero_media.hm_svg : undefined}
                      svg_animation={hero_media.hm_svg_animation}
                      priority={true}
                      objectFit={hero_media.object_fit}
                      options={options}
                    />
                  )}
                </VideoFrame>
              </motion.div>
            </div>
          </div>

          <div className="frame-edge" />
          <div className="frame-edge" />
        </div>
      </motion.section>
      <PageBreadcrumb post={post} isSinglePage={isSinglePage} />
    </>
  );
};
