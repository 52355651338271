import { sanitize } from "isomorphic-dompurify";
import { motion } from "framer-motion";
import { useEffect } from "react";

import {
  PageType,
  OptionsType,
  InlineLinkType,
  header_project,
} from "../../utils/baseTypes";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import Media from "../Media/Media";
import ScrollLink from "./ScrollLink";
import { useAppContext } from "../../context/AppContext";
import { useInView } from "react-intersection-observer";
import useWindowSize from "../../hooks/useWindowSize";
import useAnimeKey from "../../hooks/useAnimeKey";
interface ProjectHeaderProp {
  post: PageType;
  options: OptionsType;
}

const transition_ = {
  ease: [0.455, 0.03, 0.515, 0.955],
  duration: 0.85,
};

const text_variants = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: transition_,
  },
};

const container = {
  visible: {
    transition: {
      delay: 0.5,
      staggerChildren: 0.1,
    },
  },
};

const linkGenerator = (modules: any[]) => {
  const links: InlineLinkType[] = [];
  const blacklist = ["cta"];

  for (let index = 0; index < modules.length; index++) {
    const module_raw = modules[index];
    const acf_fc_layout = module_raw.acf_fc_layout;

    if (!blacklist.includes(acf_fc_layout)) {
      if (module_raw[acf_fc_layout].divider) {
        if (
          module_raw[acf_fc_layout].divider.divider_on &&
          module_raw[acf_fc_layout].divider.subline
        ) {
          links.push({
            name: module_raw[acf_fc_layout].divider.subline,
            id: module_raw.layout.id,
          });
        }
      } else if (module_raw.divider) {
        if (module_raw.divider.divider_on && module_raw.divider.subline) {
          links.push({
            name: module_raw.divider.subline,
            id: module_raw.layout.id,
          });
        }
      }
    }
  }

  return links;
};

const ProjectHeader = ({ post, options }: ProjectHeaderProp) => {
  const { width } = useWindowSize();
  const isTablet = width < 1023;
  const links = linkGenerator(post.acf?.module as any[]);

  const { modus, show_media } = post.acf?.header_project as header_project;

  const media = post.acf?.header_project?.media.media;
  const mediaType = post.acf?.header_project?.media.media.type;

  const animeKey = useAnimeKey({ ext: "project-header" });
  const { setInlineLinks, setInlineNavInView, setShowInlineNav } =
    useAppContext();

  const [navRef, navInView] = useInView();
  useEffect(() => {
    setInlineLinks(links);
    setShowInlineNav(modus === "nav-on" ? true : false);

    return () => {
      setInlineLinks([]);
      setShowInlineNav(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  useEffect(() => {
    if (isTablet) {
      return setInlineNavInView(false);
    }
    setInlineNavInView(navInView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navInView, width]);

  const TitleJSX = (
    <>
      <motion.div>
        {post.acf?.header_project?.overline && (
          <motion.span
            variants={text_variants}
            className="overline mb-3"
            dangerouslySetInnerHTML={{
              __html: sanitize(post.acf?.header_project?.overline),
            }}
          ></motion.span>
        )}
        <motion.h1 variants={text_variants}>
          <strong
            dangerouslySetInnerHTML={{
              __html: sanitize(post.acf?.header_project?.title!, {
                ADD_ATTR: ["target"],
              }),
            }}
          ></strong>
        </motion.h1>
      </motion.div>
    </>
  );

  const contentJSX = (
    <motion.div
      className="content"
      variants={text_variants}
      dangerouslySetInnerHTML={{
        __html: sanitize(post.acf?.header_project?.content!, {
          ADD_ATTR: ["target"],
        }),
      }}
    />
  );

  const categoriesJSX = (
    <motion.div className={`categories ${modus}`} variants={text_variants}>
      {post.project_categories?.map((category, i) => {
        return (
          <motion.span
            variants={text_variants}
            className="is-uppercase"
            key={i}
          >
            {category}
          </motion.span>
        );
      })}
    </motion.div>
  );

  //! addded demo links
  const navigationsJSX = (
    <motion.div className="navigation" ref={navRef} variants={text_variants}>
      {links.map((link, i) => {
        return <ScrollLink behavior={"auto"} extend key={i} link={link} />;
      })}
    </motion.div>
  );

  let column;

  if (modus === "nav-on") {
    column = (
      <>
        <div className="column is-full is-two-thirds-desktop">
          {TitleJSX}
          {contentJSX}
          {show_media ? (isTablet ? null : categoriesJSX) : null}
        </div>
        <div className="column is-offset-1-desktop">
          {show_media ? (isTablet ? categoriesJSX : navigationsJSX) : null}
        </div>
      </>
    );
  } else {
    column = (
      <>
        <div className="column is-12 is-6-desktop">{TitleJSX}</div>
        <div className="column is-12 is-6-desktop">
          {contentJSX}
          {show_media ? categoriesJSX : null}
        </div>
      </>
    );
  }

  return (
    <>
      <motion.section
        key={animeKey}
        variants={container}
        initial="hidden"
        animate="visible"
        className={`project-header ${
          post.acf?.header_project?.background
            ? post.acf?.header_project.background
            : "has-white-bg"
        }  ${show_media ? "split-gradient" : "pb-6"}`}
      >
        <Breadcrumbs post={post} options={options} />
        <div className={`container pt-3`}>
          <div className="project-details-wrapper">
            <div className="columns project-details">{column}</div>
            {show_media && (
              <motion.div variants={text_variants} className="project-media">
                <div
                  className={`max-h-35 ${
                    mediaType === "video"
                      ? "column mx-auto is-10-widescreen is-8-fullhd"
                      : ""
                  }`}
                >
                  <Media
                    data={{
                      media: media!,
                    }}
                    options={options}
                    noSection
                    objectFit="contain"
                    sizes="100vw"
                  />
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </motion.section>
    </>
  );
};

export default ProjectHeader;
