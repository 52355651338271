import { motion } from "framer-motion";
import { HeroPageProps } from ".";
import { useAnimeKeyWithDep } from "../../../../hooks/useAnimeKey";
import useWindowSize from "../../../../hooks/useWindowSize";
import { HeaderSeiteType } from "../../../../utils/baseTypes";
import { PageHeadline, PageCTA, PageContent, PageBreadcrumb } from "../Common";
import { stagger_variant } from "../../../../utils/transition";
import { getImageType, getVideoType } from "../../../../utils/responsiveFile";
import { Section } from "../../../Global/Section";
import { useRouter } from "next/router";
import Image from "next/image";
import { SvgIcon } from "../../../Global/SvgIcon";
import dynamic from "next/dynamic";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { Suspense, lazy } from "react";
import { MediaItem } from "../../../Global/MediaItem";

const ReactPlayer = dynamic(() => import("react-player"), {
  ssr: false,
  loading: () => <></>,
});

import LazyVideo from "./Video";

export const HeroFullScreen = ({
  isSinglePage,
  post,
  options,
}: HeroPageProps) => {
  const { width, height } = useWindowSize();
  const { asPath, locale } = useRouter();

  const animeKey = useAnimeKeyWithDep({
    ext: "hero-fullscreen",
    deps: [width, height, asPath, locale],
  });

  const header = post.acf?.header_seite as HeaderSeiteType;

  const {
    background,
    header_height,
    with_borders,
    hero_media,

    title,
    content,
    subline,
    number_cols,
    tpos_class,
    hpos_class,
    link,
    title_size,
    cta_type,
    button_label,
  } = header;
  const activeImage = getImageType({
    width: width,
    images: {
      image: hero_media.hm_image,
      image_tablet: hero_media.hm_tablet,
      image_mobile: hero_media.hm_mobile,
    },
  });

  const activeVideo = getVideoType({
    videos: {
      video: hero_media.hm_video.hm_video,
      video_tablet: hero_media.hm_video.upload_video_tablet,
      video_mobile: hero_media.hm_video.upload_video_mobile,
    },
    width: width,
  });

  const iframe_url = hero_media.hm_video.externes_video
    ? (hero_media.hm_video.externes_video.match(
        /<iframe.*?src=["'](.*?)["']/
      ) || [])[1]
    : "";

  return (
    <>
      <motion.section
        initial="initial"
        whileInView={"animate"}
        variants={stagger_variant}
        viewport={{ once: true }}
        key={animeKey}
        className={`hero-page  ${background} ${header_height} ${
          isSinglePage ? "is-single-page" : ""
        } ${with_borders ? "border-on" : "border-off"}`}
      >
        <div
          className={`frame ${with_borders ? "frame-on" : "frame-off"} h-100`}
        >
          <div className={`border`}>
            <div className="background-full-media">
              {activeImage &&
                activeImage.url &&
                hero_media.hm_type === "image" && (
                  <Image
                    src={activeImage.url}
                    alt={activeImage.alt}
                    fill={true}
                    style={{ objectFit: "cover" }}
                  />
                )}
              {hero_media.hm_type === "svg" && (
                <div className="background-media">
                  <SvgIcon
                    svg={hero_media.hm_svg ? hero_media.hm_svg : undefined}
                    svg_animation={hero_media.hm_svg_animation}
                    priority={true}
                    options={options}
                  />
                </div>
              )}

              {hero_media.hm_type === "video" && (
                <>
                  {hero_media.hm_video.video_type === "upload" && (
                    <div className="background-media">
                      {activeVideo && (
                        <LazyVideo
                          activeVideo={activeVideo}
                          poster={{
                            desktop: hero_media.hm_image,
                            tablet: hero_media.hm_tablet,
                            mobile: hero_media.hm_mobile,
                          }}
                        />
                      )}
                    </div>
                  )}

                  {(hero_media.hm_video.video_type === "Youtube" ||
                    hero_media.hm_video.video_type === "Vimeo") && (
                    <>
                      {iframe_url && (
                        <ReactPlayer
                          url={iframe_url}
                          playing
                          loop
                          muted
                          playsinline
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="frame-edge" />
          <div className="frame-edge" />
        </div>
      </motion.section>
      <PageBreadcrumb post={post} isSinglePage={isSinglePage} />
      <Section className="pt-0" disableColumns>
        <div className="columns is-centered">
          <div className="column is-12-tablet is-9-widescreen">
            <PageHeadline
              tpos_class={tpos_class}
              subline={subline}
              title={title}
              title_size={title_size}
            />
            {content && (
              <PageContent
                hpos_class={hpos_class}
                tpos_class={tpos_class}
                number_cols={number_cols}
                content={content}
              />
            )}
            <PageCTA
              link={link}
              cta_type={cta_type}
              button_label={button_label}
              options={options}
            />
          </div>
        </div>
      </Section>
    </>
  );
};
