import { ImageType, VideoFileType } from "./baseTypes";

export const getImageType = ({
  width,
  images,
}: {
  width: number;
  images: {
    image: ImageType | false;
    image_tablet: ImageType | false;
    image_mobile: ImageType | false;
  };
}): ImageType | false => {
  if (width >= 1024 && images.image) {
    return images.image;
  } else if (width >= 768 && images.image_tablet) {
    return images.image_tablet;
  } else if (width < 768 && images.image_mobile) {
    return images.image_mobile;
  } else {
    return images.image;
  }
};

export const getVideoType = ({
  width,
  videos,
}: {
  width: number;
  videos: {
    video: VideoFileType | false;
    video_tablet: VideoFileType | false;
    video_mobile: VideoFileType | false;
  };
}): VideoFileType | false => {
  if (width >= 1024 && videos.video) {
    return videos.video;
  } else if (width >= 768 && videos.video_tablet) {
    return videos.video_tablet;
  } else if (width < 768 && videos.video_mobile) {
    return videos.video_mobile;
  } else {
    return videos.video;
  }
};
