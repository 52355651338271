import React from "react";
import LoadMore from "../../images/load_more.svg";
import LoadMoreWhite from "../../images/load_more_white.svg";

interface LoadMoreIconProp {
  background?: string;
}

export const LoadMoreIcon = ({
  background = "has-white-bg",
}: LoadMoreIconProp) => {
  const invert =
    background === "has-gradient-bg" || background === "has-dark-grey-bg";

  return invert ? (
    <LoadMoreWhite width={70} height={70} />
  ) : (
    <LoadMore width={70} height={70} />
  );
};
