import { useEffect, useState } from "react";
import { Logo } from "../Navbar/Logo/Logo";
import { OptionsType, PageType } from "../../utils/baseTypes";
import Aunds from "../Navbar/Aunds";
import { ModalForm } from "../Form/ModalForm";
import { sanitize } from "isomorphic-dompurify";

interface NavbarProps {
  options: OptionsType;
  className?: string;
  defaultBg?: boolean;
  page: PageType;
}

export const BNavbar = ({ options, page }: NavbarProps) => {
  const [hoverContact, setHoverContact] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const title = options.acf.titel;
  const position = options.acf.funktion;

  useEffect(() => {
    document.getElementById("main")?.classList.add("hidden-menu-main");
  }, []);

  return (
    <>
      <header className="hidden-menu">
        <nav className="navbar-custom berlinfahrten">
          <div className="navbar-top">
            <div className="nav-left">
              <Logo options={options} />
              <div className="logo-text">
                <h3>{title}</h3>
                <p>{position}</p>
              </div>
            </div>
            <div className="nav-right">
              <div className="text is-hidden-touch last">
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(options.acf.header_text),
                  }}
                ></span>
              </div>
              <div className="contact berlinfahrten">
                <span
                  className="a is-capitalized contact-link"
                  onMouseEnter={() => {
                    setHoverContact(true);
                  }}
                  onMouseLeave={() => {
                    setHoverContact(false);
                  }}
                  onClick={() => setModalOpen(true)}
                >
                  <span className="contact-title">
                    {options.acf.header_contact_link.title}
                  </span>
                  &nbsp;
                  <Aunds
                    width={40}
                    height={30}
                    color={"#fff"}
                    isHover={hoverContact}
                  />
                </span>
              </div>
            </div>
          </div>
        </nav>
        <ModalForm
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          options={options}
        />
      </header>
    </>
  );
};
