import { PageType, OptionsType } from "../../utils/baseTypes";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { Section } from "../Global/Section";

interface PostHeaderProps {
  post: PageType;
  options: OptionsType;
}

export const PostHeader = ({ post, options }: PostHeaderProps) => {
  return (
    <>
      <Section
        className="small-header has-grey-bg pt-0"
        containerClassName="container"
      >
        <div className="column">
          <Breadcrumbs post={post} options={options} />
          <div className="meta post-meta">
            <span className="date">
              {new Date(post.date).toLocaleDateString("de", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
              {post?.acf?.header_beitrag?.tags ? " | " : ""}
            </span>
            <span>
              {post.acf?.header_beitrag?.tags &&
                post.acf.header_beitrag.tags.map((tag, i) => {
                  return (
                    <span className="post-tag" key={i}>
                      {tag.tag}
                    </span>
                  );
                })}
            </span>
          </div>

          <h1>{post.acf?.header_beitrag?.title}</h1>
          <div className="share is-flex is-align-items-center is-justify-content-center">
            <span>TEILEN </span>
            {/* <SocialMediaShareButton
              social_network="facebook"
              share_url={post.link}
            />
            <SocialMediaShareButton
              social_network="twitter"
              share_url={post.link}
            /> */}
          </div>
        </div>
      </Section>
    </>
  );
};
