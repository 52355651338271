import React from "react";
import {
  HeaderSeiteType,
  OptionsType,
  PageType,
} from "../../../utils/baseTypes";
import { Section } from "../../Global/Section";
import { motion } from "framer-motion";
import {
  PageBreadcrumb,
  PageCTA,
  PageContent,
  PageDivider,
  PageHeadline,
} from "./Common";
import { stagger_variant } from "../../../utils/transition";
import useAnimeKey from "../../../hooks/useAnimeKey";

interface TextHeaderProps {
  post: PageType;
  isSinglePage: boolean;
  options: OptionsType;
  disablehHeight?: boolean;
  isAnimationSlider?: boolean;
}

export const TextHeader = ({
  post,
  isSinglePage,
  options,
  disablehHeight = false,
  isAnimationSlider = false,
}: TextHeaderProps) => {
  const header = post.acf?.header_seite as HeaderSeiteType;

  const {
    title,
    content,
    subline,
    background,
    divider,
    number_cols,
    hpos_class,
    header_height,
    tpos_class,
    title_size,
    link,
    cta_type,
    button_label,
  } = header;

  const animekey = useAnimeKey({ ext: "text-header" });

  //! changing size of column
  let columnSize: string;
  switch (hpos_class) {
    case "headline-beside-text":
      columnSize = "is-12 is-6-desktop";
      break;
    case "headline-over-text":
      columnSize = "is-12";
      break;
    default:
      columnSize = "is-12";
      break;
  }

  return (
    <section
      className={`text-header divider ${background} ${
        disablehHeight ? "" : header_height
      } ${isSinglePage ? "is-single-page" : ""}`}
    >
      <PageBreadcrumb isSinglePage={isSinglePage} post={post} />
      <Section className="padding-top-0" disableColumns>
        <motion.div
          initial="initial"
          whileInView={"animate"}
          variants={stagger_variant}
          viewport={{ once: true }}
          key={animekey}
          className="columns is-multiline is-centered"
        >
          <PageDivider background={background} divider={divider} />
          <div
            className={`column is-12 ${isAnimationSlider ? "max-width" : ""}`}
          >
            <div className="columns is-multiline headline-wrapper">
              <div className={`column ${columnSize} `}>
                <PageHeadline
                  subline={subline}
                  title={title}
                  tpos_class={tpos_class}
                  title_size={title_size}
                />

                <PageCTA
                  link={link}
                  cta_type={cta_type}
                  button_label={button_label}
                  options={options}
                />
              </div>
              {content && (
                <div className={`column ${columnSize}`}>
                  <PageContent
                    hpos_class={hpos_class}
                    number_cols={number_cols}
                    tpos_class={tpos_class}
                    content={content}
                  />
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </Section>
    </section>
  );
};
