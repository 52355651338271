import { useState, Dispatch, SetStateAction, useRef } from "react";

import Close from "../../images/close_x.svg";
import { AnimatePresence, motion } from "framer-motion";

import Failed from "../../images/form/contact_form_failed.svg";
import Thanks from "../../images/form/contact_form_thanks.svg";
import { SimpleButton } from "../Global/Button";
import { Loader } from "../../Loader/Loader";
import { ContactForm } from "./ContactForm";
import { BerlinfahrtenForm } from "./BerlinfahrtenForm";
import { OptionsType } from "../../utils/baseTypes";

interface FormProp {
  showClose?: boolean;
  setModalOpen?: Dispatch<SetStateAction<boolean>>;
  form?: "berlinfahrten" | "contactform";
  options: OptionsType;
}

export const form_transition = {
  ease: [0.455, 0.03, 0.515, 0.955],
  duration: 1,
};

export const form_animation = {
  hidden: {
    x: "100%",
    opacity: 1,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: form_transition,
  },
  exit: {
    x: "-100%",
    opacity: 0,
    transition: form_transition,
  },
};

export const Form = ({
  showClose = false,
  setModalOpen,
  form = "contactform",
  options,
}: FormProp) => {
  const [formState, setformState] = useState<
    "entry" | "success" | "error" | "loading"
  >("entry");

  const divRef = useRef<HTMLDivElement>(null);
  const [prevHeight, setPrevHeight] = useState<number>(0);

  return (
    <div className="form-wrapper hide-overflow">
      <motion.div
        ref={divRef}
        style={{
          height: prevHeight,
          position: "relative",
        }}
        layout
        className="columns"
      >
        <AnimatePresence>
          {formState === "loading" && <Loader />}

          {formState === "entry" && (
            <>
              {form === "contactform" && (
                <ContactForm
                  key={"entry"}
                  setformState={setformState}
                  setPrevHeight={setPrevHeight}
                  options={options}
                />
              )}

              {form === "berlinfahrten" && (
                <BerlinfahrtenForm
                  key={"entry"}
                  setformState={setformState}
                  setPrevHeight={setPrevHeight}
                  options={options}
                />
              )}
            </>
          )}
          {formState === "success" && (
            <Success
              key={"success"}
              setformState={setformState}
              prevHeight={prevHeight}
              options={options}
            />
          )}
          {formState === "error" && (
            <Error
              key={"error"}
              setformState={setformState}
              prevHeight={prevHeight}
              options={options}
            />
          )}
        </AnimatePresence>
      </motion.div>

      <div className="corner-div" />
      <div className="corner-div" />
      {showClose && (
        <div className="close">
          <button
            className="close-button"
            onClick={() => {
              if (setModalOpen) {
                setModalOpen((last) => !last);
              }
            }}
          >
            <Close width={22} height={22} />
          </button>
        </div>
      )}
    </div>
  );
};

export interface formStateProp {
  setformState: Dispatch<
    SetStateAction<"entry" | "success" | "error" | "loading">
  >;
  setPrevHeight?: Dispatch<SetStateAction<number>>;
  prevHeight?: number;
  options: OptionsType;
}

//todo  optimize error message showing

const Success = ({ prevHeight, options }: formStateProp) => {
  const successTitle = options.acf.success_title;
  const successText = options.acf.success_content;

  return (
    <motion.div
      variants={form_animation}
      initial={"hidden"}
      animate={"visible"}
      exit={"exit"}
      className="form-state column is-12"
      style={{
        minHeight: prevHeight,
      }}
    >
      <motion.div
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
          transition: {
            delay: 0.5,
            ...form_transition,
          },
        }}
        viewport={{ once: true }}
        className="form-state-content"
      >
        <div className="columns is-centered">
          <div className="column is-12 is-flex is-justify-content-center is-align-items-center">
            <Thanks width={137} height={129} />
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-10 is-flex is-justify-content-center is-align-items-center">
            <span className="bold-text has-text-centered">{successTitle}</span>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-8 is-flex is-justify-content-center is-align-items-center">
            <span
              className="has-text-centered"
              dangerouslySetInnerHTML={{ __html: successText }}
            />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const Error = ({ setformState, prevHeight, options }: formStateProp) => {
  const errorTitle = options.acf.error_title;
  const errorText = options.acf.error_content;
  return (
    <motion.div
      variants={form_animation}
      initial={"hidden"}
      animate={"visible"}
      exit={"exit"}
      className="form-state column is-12"
      style={{
        minHeight: prevHeight,
      }}
    >
      <motion.div
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
          transition: {
            delay: 0.5,
            ...form_transition,
          },
        }}
        viewport={{ once: true }}
        className="form-state-content"
      >
        <div className="columns is-centered">
          <div className="column is-12 is-flex is-justify-content-center is-align-items-center">
            <Failed width={129} height={129} />
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-12 is-flex is-justify-content-center is-align-items-center">
            <span className="bold-text has-text-centered">{errorTitle}</span>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-6 is-flex is-justify-content-center is-align-items-center">
            <span
              className="has-text-centered"
              dangerouslySetInnerHTML={{ __html: errorText }}
            />
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-12 is-flex is-justify-content-center is-align-items-center">
            <SimpleButton
              type="button"
              className="btn is-primary"
              text="ZURÜCK ZUM FORMULAR"
              onClick={() => setformState("entry")}
            />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};
