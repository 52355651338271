import { sanitize } from "isomorphic-dompurify";
import { motion } from "framer-motion";
import {
  PageType,
  OptionsType,
  isAvailable,
  HeaderJoinusType,
  ImageType,
} from "../../utils/baseTypes";
import { Section } from "../Global/Section";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import Divider from "../Divider/Divider";
import { BannerJob } from "./BannerJob/BannerJob";

import { useDividerAnimation } from "../../utils/useDividerAnimation";
interface JoinUsHeaderProp {
  post: PageType;
  options?: OptionsType;
}

const transition_ = {
  ease: [0.455, 0.03, 0.515, 0.955],
  duration: 0.85,
};

const text_variants = {
  hidden: {
    y: "100%",
  },
  visible: {
    y: 0,
    transition: transition_,
  },
};
const media_variants = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: transition_,
  },
};

const stagger_variant = {
  visible: {
    transition: {
      delay: 0.5,
      staggerChildren: 0.3,
    },
  },
};

export const JoinUsHeader = ({ post }: JoinUsHeaderProp) => {
  const header = isAvailable<HeaderJoinusType>(post.acf?.header_joinus)
    ? post.acf?.header_joinus
    : null;

  const svg = isAvailable<ImageType>(post.acf?.header_joinus.svg)
    ? post.acf?.header_joinus.svg
    : null;

  const animation = post.acf?.header_joinus?.animation;

  useDividerAnimation(animation ?? "");

  return (
    <section
      className={`joinus-header divider p-4 ${header?.background ?? ""}`}
    >
      <Breadcrumbs post={post} />
      {!post.acf?.header_joinus.show_in_jobs && (
        <BannerJob banner={post.acf?.header_joinus.banner!} />
      )}
      <Section disableColumns className="is-gapless-top">
        {header?.divider.divider_on && (
          <Divider
            data={header?.divider!}
            background={header?.background ?? ""}
          />
        )}
        <div className="columns">
          <div className="column is-hidden-touch">
            <motion.div
              variants={media_variants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="media-wrapper"
            >
              {svg && (
                <object
                  data={svg.url!}
                  width={280}
                  height={280}
                  title={svg.title}
                />
              )}
            </motion.div>
          </div>
          <motion.div
            variants={stagger_variant}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="column"
          >
            <div className="overflow-hidden">
              <motion.div variants={text_variants}>
                <h1 className="title">
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: sanitize(header?.title!),
                    }}
                  ></strong>
                </h1>
                <span className="underline">{header?.subline}</span>
              </motion.div>
            </div>
            <div className="overflow-hidden">
              <motion.div
                variants={text_variants}
                dangerouslySetInnerHTML={{
                  __html: sanitize(header?.content!, {
                    ADD_ATTR: ["target"],
                  }),
                }}
              />
            </div>
          </motion.div>
        </div>
      </Section>
    </section>
  );
};
