import React from "react";
import { LinkType } from "../../utils/baseTypes";
import { sanitize } from "isomorphic-dompurify";
import { Button } from "../Global/Button";

export type CTASlideType = {
  bg_color_cta: string;
  content: string;
  headline: string;
  link: LinkType;
};

interface PortraitCTASlideProps {
  CTA: CTASlideType;
}

export const PortraitCTASlide = ({ CTA }: PortraitCTASlideProps) => {
  const { bg_color_cta, content, headline, link } = CTA;
  return (
    <div className={`portrait-cta-slide ${bg_color_cta}`}>
      <div className="portrait-cta-info-wrapper">
        <span className="h3 portrait-cta-headline">{headline}</span>
        <div
          className="portrait-cta-text"
          dangerouslySetInnerHTML={{ __html: sanitize(content) }}
        />
        {link && (
          <Button
            title={link.title}
            type={"primary"}
            href={link.url}
            target={link.target}
            className="portrait-cta-button"
          />
        )}
      </div>
    </div>
  );
};
