import dynamic from "next/dynamic";
const TextMedia = dynamic(() => import("../components/TextMedia/TextMedia"));
const AccordionModule = dynamic(
  () => import("../components/Accordion/AccordionModule")
);
import { TextModuleType } from "../components/Text/Text";
const Text = dynamic(() => import("../components/Text/Text"));
import { SocialsModuleType } from "../components/Socials/Socials";
import {
  AzFilterPostType,
  OptionsType,
  PageType,
  WikiType,
  CategoryDataType,
  PagenationDataType,
  EncyclopediaCategoryType,
  SEOListType,
} from "./baseTypes";
import { MediaModuleType } from "../components/Media/Media";
const Media = dynamic(() => import("../components/Media/Media"));
import { CTATypeModule } from "../components/CTA/CTA";
const CTA = dynamic(() => import("../components/CTA/CTA"));
import { PromoBannerType } from "../components/PromoBanner/PromoBanner";
const PromoBanner = dynamic(
  () => import("../components/PromoBanner/PromoBanner")
);
import { AutorenType } from "../components/Autoren/Autoren";
const Autoren = dynamic(() => import("../components/Autoren/Autoren"));
import { GetStaticPropsContext } from "next";
const AzGrid = dynamic(() => import("../components/Wiki/AzGrid"));
import { AzGridType } from "../components/Wiki/AzGrid";
import { TextMediaGridType } from "../components/TextMediaGrid/TextMediaGrid";

const TextMediaGrid = dynamic(
  () => import("../components/TextMediaGrid/TextMediaGrid")
);
import { WikisOverviewtype } from "../components/Wiki/Wikis";
const Wikis = dynamic(() => import("../components/Wiki/Wikis"));
import { BlogcastType } from "../components/Blogcast/Blogcast";
const Blogcast = dynamic(() => import("../components/Blogcast/Blogcast"));
import { PortraitSliderModuleType } from "../components/PortraitSlider/PortraitSliderModule";
const PortraitSliderModule = dynamic(
  () => import("../components/PortraitSlider/PortraitSliderModule")
);
import { TextInfoBoxType } from "../components/TextInfoBox/TextInfoBox";
const TextInfoBox = dynamic(
  () => import("../components/TextInfoBox/TextInfoBox")
);
import { TextCTAModuleType } from "../components/TextCTA/TextCTA";
const TextCATModule = dynamic(() => import("../components/TextCTA/TextCTA"));
import { FeatureBoxType } from "../components/FeatureBox/FeatureBox";
const FeatureBox = dynamic(() => import("../components/FeatureBox/FeatureBox"));
import { JobsType } from "../components/Jobs/Jobs";
const Jobs = dynamic(() => import("../components/Jobs/Jobs"));
import { InsightsModuleType } from "../components/Insights/Insights";
const Insights = dynamic(() => import("../components/Insights/Insights"));
import { LogoType, LogosModuleType } from "../components/Logos/Logos";
const Logos = dynamic(() => import("../components/Logos/Logos"));
import { VideoSliderModuleType } from "../components/VideoSlider/VideoSlider";
const VideoSlider = dynamic(
  () => import("../components/VideoSlider/VideoSlider")
);
import { TeamsModuleType } from "../components/Teams/Teams";
const Teams = dynamic(() => import("../components/Teams/Teams"));
import { FormModuleType } from "../components/Form/FormModule";
const FormModule = dynamic(() => import("../components/Form/FormModule"));
import { PortfolioModuleType } from "../components/Portfolio/PortfolioModule";
const PortfolioModule = dynamic(
  () => import("../components/Portfolio/PortfolioModule")
);
import { SEOPageModuleType } from "../components/SEOPage/SEOPage";
const SEOPage = dynamic(() => import("../components/SEOPage/SEOPage"));
const RecruitingForm = dynamic(
  () => import("../components/Form/RecruitingForm/RecruitingForm")
);
import { ImageSliderModuleType } from "../components/ImageSlider/ImageSliderModule";
const ImageSliderModule = dynamic(
  () => import("../components/ImageSlider/ImageSliderModule")
);
import { PricingModuleType } from "../components/Pricing/Pricing";
const Pricing = dynamic(() => import("../components/Pricing/Pricing"));
import { ScrollAnimationModuleType } from "../components/ScrollAnimation/ScrollAnimationModule";
const ScrollAnimationModule = dynamic(
  () => import("../components/ScrollAnimation/ScrollAnimationModule")
);
import { AwardsType } from "../components/Awards/Awards";
const Awards = dynamic(() => import("../components/Awards/Awards"));
import { AccordionType } from "../components/Accordion/AccordionModule";
import { TextMediaModuleType } from "../components/TextMedia/TextMedia";
import { FactsModuleType } from "../components/Facts/Facts";
const Facts = dynamic(() => import("../components/Facts/Facts"));

interface ModulesProps {
  module:
    | TextModuleType
    | TextMediaModuleType
    | AccordionType
    | SocialsModuleType
    | MediaModuleType
    | CTATypeModule
    | PromoBannerType
    | AutorenType
    | AzGridType
    | WikisOverviewtype
    | TextMediaGridType
    | BlogcastType
    | PortraitSliderModuleType
    | TextInfoBoxType
    | TextCTAModuleType
    | FeatureBoxType
    | JobsType
    | InsightsModuleType
    | LogosModuleType
    | VideoSliderModuleType
    | TeamsModuleType
    | FormModuleType
    | PortfolioModuleType
    | SEOPageModuleType
    | ImageSliderModuleType
    | PricingModuleType
    | ScrollAnimationModuleType
    | AwardsType
    | FactsModuleType;

  options?: OptionsType;
  ctaPersons?: PageType[];
  AuthorsGridSlider: PagenationDataType;
  context: GetStaticPropsContext;
  wikiPosts?: AzFilterPostType;
  wikiFilterLetters: string[] | null;
  filteredWikiPosts?: WikiType[];
  JobsData?: PageType[];
  InsightData: PagenationDataType;
  InsightCategoryData: CategoryDataType[];
  InsightHighlightedPosts: PageType[];
  azCategories: EncyclopediaCategoryType[];
  TeamsData: PageType[];
  PortfolioData: PagenationDataType;
  PortfolioCategoryData: CategoryDataType[];
  SEOList: SEOListType[];
  type: string;
  PortraitSliderModulePortfolio: PageType[];
  pageId: number;
  totalWikiPosts: number;
  lastIsPromo: boolean;
  LogosData: LogoType[];
}

const Modules = (props: ModulesProps) => {
  switch (props.module.acf_fc_layout) {
    case "text":
      return (
        <Text data={props.module} type={props.type} options={props.options!} />
      );
    case "textmedia":
      return <TextMedia data={props.module} options={props.options!} />;
    case "media":
      return (
        <Media data={props.module} options={props.options!} type={props.type} />
      );
    case "cta":
      return (
        <CTA
          data={props.module}
          options={props.options!}
          ctaPersons={props.ctaPersons!}
        />
      );
    case "promo_banner":
      return (
        <PromoBanner data={props.module} lastIsPromo={props.lastIsPromo} />
      );
    case "authors":
      return (
        <Autoren
          data={props.module}
          AuthorsGridSlider={props.AuthorsGridSlider!}
          context={props.context}
          options={props.options!}
        />
      );
    case "wikis":
      return (
        <Wikis
          data={props.module}
          wikiPosts={props.wikiPosts!}
          context={props.context}
          wikiFilterLetters={props.wikiFilterLetters!}
          options={props.options!}
        />
      );
    case "az_grid":
      return (
        <AzGrid
          data={props.module}
          filteredWikiPosts={props.filteredWikiPosts!}
          context={props.context}
          azCategories={props.azCategories}
          totalWikiPosts={props.totalWikiPosts}
        />
      );
    case "textmedia_grid":
      return <TextMediaGrid data={props.module} options={props.options!} />;
    case "blogcast":
      return <Blogcast data={props.module} options={props.options!} />;
    case "slider":
      return (
        <PortraitSliderModule
          data={props.module}
          PortraitSliderModulePortfolio={props.PortraitSliderModulePortfolio}
        />
      );
    case "text_infobox":
      return <TextInfoBox data={props.module} />;
    case "text_cta":
      return <TextCATModule data={props.module} options={props.options!} />;
    case "feature_box":
      return <FeatureBox data={props.module} />;
    case "jobs":
      return (
        <Jobs
          data={props.module}
          context={props.context}
          jobs={props.JobsData!}
        />
      );
    case "accordion":
      return <AccordionModule data={props.module} options={props.options!} />;
    case "insights":
      return (
        <Insights
          data={props.module}
          InsightCategoryData={props.InsightCategoryData}
          InsightData={props.InsightData}
          context={props.context}
          pageId={props.pageId}
          pageType={props.type}
          InsightHighlightedPosts={props.InsightHighlightedPosts}
        />
      );
    case "logos":
      if (props.LogosData.length > 0) {
        return <Logos data={props.module} LogosData={props.LogosData} />;
      } else {
        return null;
      }
    case "video_slider":
      return <VideoSlider data={props.module} options={props.options!} />;
    case "team":
      return <Teams data={props.module} TeamsData={props.TeamsData} />;
    case "forms":
      // return <FormModule data={props.module} />;
      switch (props.module.forms.typ) {
        case "contact-form":
          return <FormModule data={props.module} options={props.options!} />;
        case "recruiting-form":
          return (
            <RecruitingForm data={props.module} jobsData={props.JobsData!} />
          );
        case "newsletter-form":
          return <FormModule data={props.module} options={props.options!} />;
      }
    case "portfolio":
      return (
        <PortfolioModule
          data={props.module}
          PortfolioCategoryData={props.PortfolioCategoryData}
          PortfolioData={props.PortfolioData}
          options={props.options!}
          context={props.context}
        />
      );
    case "seo_list":
      return <SEOPage data={props.module} SEOList={props.SEOList} />;
    case "image_slider":
      return <ImageSliderModule data={props.module} />;
    case "pricing":
      return <Pricing data={props.module} options={props.options!} />;
    case "scrollanimation":
      return <ScrollAnimationModule data={props.module} />;
    case "awards":
      return <Awards data={props.module} />;
    case "facts":
      return <Facts data={props.module} />;
    default:
      return null;
  }
};

export default Modules;
