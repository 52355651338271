import { getUrl } from "../utils/url";

export const getImageCaption = async (
  id: string,
  lang?: string,
  defaultLang?: string
) => {
  const media = await fetch(
    getUrl(lang, defaultLang) + "/media/" + id + "?_fields=acf"
  );
  return await media.json();
};
