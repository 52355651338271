import { LinkType } from "../../utils/baseTypes";
import Link from "next/link";
export const FrameWrapper = ({
  children,
  wrapLink,
  link,
  className,
}: {
  children: React.ReactNode;
  wrapLink: boolean;
  link?: LinkType;
  className?: string;
}) => {
  return wrapLink ? (
    <Frame>
      <div>
        <Link
          prefetch={false}
          className={className ?? ""}
          href={link ? link.url : "#"}
          target={link ? link.target : "_self"}
        >
          {children}
        </Link>
      </div>
    </Frame>
  ) : (
    <>{children}</>
  );
};

export const Frame = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="frame-box-wrapper">
      {children}
      {/* below div's are for gradient corner on hover */}
      <div className="corner-div" />
      <div className="corner-div" />
    </div>
  );
};
