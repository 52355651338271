import { ReactNode, Dispatch, SetStateAction } from "react";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { AnimatePresence, motion } from "framer-motion";
import { full_y_variant_default } from "../../utils/transition";

interface ModalProp {
  children: ReactNode;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  showCloseBtn?: boolean;
}

export const Modal = ({
  children,
  modalOpen = false,
  setModalOpen,
  showCloseBtn = false,
}: ModalProp) => {
  useUpdateEffect(() => {
    setModalOpen(modalOpen);
  }, [modalOpen]);

  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          initial="initial"
          animate="animate"
          exit={"exit"}
          variants={full_y_variant_default}
          className={`modal is-active`}
        >
          <div className="modal-background"></div>
          <div
            className="modal-content"
            style={{ width: "100%", maxHeight: "100%" }}
          >
            {modalOpen && children}
          </div>
          {showCloseBtn && (
            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={() => setModalOpen((last) => !last)}
            ></button>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
