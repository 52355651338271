import React, { useEffect, useRef, useState } from "react";
import {
  EncyclopediaCategoryType,
  HeaderSeiteType,
  OptionsType,
  PageType,
} from "../../../utils/baseTypes";
import { PageBreadcrumb, PageCTA, PageContent, PageHeadline } from "./Common";
import Link from "next/link";
import { getRelativePathFromLink } from "../../../utils/url";

interface WikiPageHeaderProps {
  post: PageType;
  isSinglePage: boolean;
  wikiCategories: EncyclopediaCategoryType[];
  options: OptionsType;
}

export const WikiPageHeader = ({
  post,
  isSinglePage,
  wikiCategories,
  options,
}: WikiPageHeaderProps) => {
  const header = post.acf?.header_seite as HeaderSeiteType;

  const mainWikiCats = wikiCategories.filter((c) => c.parent === 0);

  const wikiJSX = mainWikiCats.map((cat) => {
    return <WikiCategory key={cat.name} cat={cat} />;
  });

  const {
    title,
    content,
    subline,
    background,
    number_cols,
    hpos_class,
    header_height,
    link,
    cta_type,
    button_label,
    with_borders,
    title_size,
  } = header;

  return (
    <>
      <section
        className={`wiki-page-header divider ${background} ${header_height} ${
          isSinglePage ? "is-single-page" : ""
        }`}
      >
        <div className={`frame ${with_borders ? "frame-on" : "frame-off"}`}>
          <div className="border">
            <div className="container">
              <div className="columns is-multiline headline-wrapper is-centered">
                <div className={`column is-12-tablet`}>
                  <PageHeadline
                    subline={subline}
                    title={title}
                    tpos_class={"has-text-centered"}
                    title_size={title_size}
                  />
                  <PageCTA
                    link={link}
                    cta_type={cta_type}
                    button_label={button_label}
                    options={options}
                  />
                </div>
                <div className={`column is-12-tablet is-9-desktop `}>
                  <PageContent
                    hpos_class={hpos_class}
                    number_cols={number_cols}
                    tpos_class={"has-text-centered"}
                    content={content}
                  />
                </div>
                <div
                  className={`column is-12-tablet is-flex-wrap-wrap wiki-categories is-flex`}
                >
                  {wikiJSX}
                </div>
              </div>
            </div>
          </div>
          <div className="frame-edge" />
          <div className="frame-edge" />
        </div>
      </section>
      <PageBreadcrumb post={post} isSinglePage={isSinglePage} />
    </>
  );
};

interface OdometerType {
  update: (value: number) => void;
  value: number;
}

const WikiCategory = ({ cat }: { cat: EncyclopediaCategoryType }) => {
  const spanRef = useRef<HTMLDivElement>(null);

  const [instance, setInstance] = useState<OdometerType>({
    update: () => {},
    value: 0,
  });

  const [hover, setHover] = useState<boolean>(false);

  useEffect(() => {
    // var el = document.querySelector(`#${encodeURI(cat.name.toLowerCase())}`);
    const el = spanRef.current;
    // used ts ignore because of dynamic import and typescript not recognizing it
    //@ts-ignore
    import("../../../utils/odometer").then((odometer) => {
      const Odometer = odometer.default;
      const odometerInstance = new Odometer({
        el: el,
        value: 0,
        format: "",
      });

      odometerInstance.update(cat.count);
      setInstance(odometerInstance);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      key={cat.name}
      className="wiki-category is-flex is-flex-direction-column is-align-items-center"
    >
      <div
        onMouseEnter={() => {
          if (!hover) {
            setHover(true);
            instance.value = 0;
            instance.update(cat.count);
          }
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        ref={spanRef}
        id={encodeURI(cat.name.toLowerCase())}
        className="count"
      ></div>
      <Link
        href={getRelativePathFromLink(cat.link)}
        className={"is-flex"}
        prefetch={false}
      >
        <span>{cat.name}</span>
      </Link>
    </div>
  );
};
