import { Menu } from "../Footer/Menu";
import { MenuType, OptionsType } from "../../utils/baseTypes";
// TODO enable this again once it is configured properly

import dynamic from "next/dynamic";

interface FooterProps {
  options: OptionsType;
  legalMenu: MenuType;
}

const Socials = dynamic(() => import("../Footer/Socials"), {
  loading: () => <></>,
  ssr: false,
});

export const BFooter = ({ options, legalMenu }: FooterProps) => {
  const { plattform } = options.acf;

  return (
    <footer
      className="footer has-dark-grey-bg"
      aria-label="footer"
      id="kontakt"
    >
      <div className="columns container">
        <div className="column is-12-tablet">
          <div className="columns is-centered socials-column">
            <Socials platforms={plattform} />
          </div>
          <div className="columns is-centered third">
            <div className="legal-menu-column">
              <Menu
                menu={legalMenu}
                showCompany
                options={options}
                // setShowConsentManagement={setShowConsentManagement}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <ConsentManagement
        data={options.acf}
        reloadOnSave
        setIsForceShow={setShowConsentManagement}
        isForceShow={showConsentManagement}
      />*/}
    </footer>
  );
};
