import { Dispatch, SetStateAction, useRef } from "react";
import { Modal } from "../Global/Modal";
import { Form } from "./Form";
import { OptionsType } from "../../utils/baseTypes";
import useOnClickOutside from "../../utils/useOnClickOutside";

interface ModalFormProp {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  options: OptionsType;
  form?: "berlinfahrten" | "contactform";
}

export const ModalForm = ({
  modalOpen,
  setModalOpen,
  options,
  form = "contactform",
}: ModalFormProp) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setModalOpen(false));

  return (
    <>
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div className="container">
          <div className="columns is-centered is-vcentered">
            <div ref={ref} className="column is-9-desktop">
              <Form
                showClose
                setModalOpen={setModalOpen}
                form={form}
                options={options}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
