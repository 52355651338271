import { Hero } from "./HeroSlider/Hero";
import { SeiteHeader } from "./SeiteHeader/SeiteHeader";
import { PostHeader } from "./PostHeader";
import {
  CategoryType,
  OptionsType,
  PageType,
  EncyclopediaCategoryType,
  WikiType,
  AzFilterPostType,
  AuthorInsightType,
  AuthorInsight,
} from "../../utils/baseTypes";
import AutorHeader from "./AutorHeader";
import InsightHeader from "./InsightHeader";
import WikiHeader from "./WikiHeader";
import ProjectHeader from "./ProjectHeader";
import { JoinUsHeader } from "./JoinUsHeader";
import { MotionValue } from "framer-motion";

interface HeaderProps {
  post: PageType | WikiType;
  options: OptionsType;
  categories: CategoryType[];
  wikiCategories: EncyclopediaCategoryType[];
  wikiInfo: AzFilterPostType;
  wikiFilterLetters: string[] | null;
  autorInsights: AuthorInsight;
  portraitSliderPortfolio: PageType[];
}

export const Header = ({
  post,
  options,
  categories,
  wikiCategories,
  wikiInfo,
  wikiFilterLetters,
  autorInsights,
  portraitSliderPortfolio,
}: HeaderProps) => {
  // * header_startseite should be always first because there is a bug in the REST API.

  if (post?.acf?.header_startseite) {
    return <Hero post={post.acf.header_startseite} />;
  } else if (post?.acf?.header_seite) {
    return (
      <SeiteHeader
        post={post}
        options={options}
        wikiCategories={wikiCategories}
        portraitSliderPortfolio={portraitSliderPortfolio}
      />
    );
  } else if (post?.acf?.header_autor) {
    return (
      <AutorHeader
        post={post}
        options={options}
        autorInsights={autorInsights}
      />
    );
  } else if (post?.acf?.header_insights) {
    return <InsightHeader post={post} options={options} />;
  } else if (
    (post as WikiType)?.acf?.wiki_header &&
    wikiFilterLetters != null
  ) {
    return (
      <WikiHeader
        post={post as WikiType}
        options={options}
        breadcrumbData={post}
        wikiCategories={wikiCategories}
        wikiInfo={wikiInfo}
        wikiFilterLetters={wikiFilterLetters}
      />
    );
  } else if (post?.acf?.header_project) {
    return <ProjectHeader post={post} options={options} />;
  } else if (post?.acf?.header_joinus) {
    return <JoinUsHeader post={post} options={options} />;
  } else {
    return <PostHeader post={post} options={options} />;
  }
};
