import React from "react";
import useAnimeKey from "../../../hooks/useAnimeKey";
import { PageType, HeaderSeiteType } from "../../../utils/baseTypes";
import { Section } from "../../Global/Section";
import { PageBreadcrumb } from "./Common";
import HeaderDivider from "../HeaderDivider/HeaderDivider";

interface DividerHeaderProps {
  post: PageType;
  isSinglePage: boolean;
}

export const DividerHeader = ({ post, isSinglePage }: DividerHeaderProps) => {
  const header = post.acf?.header_seite as HeaderSeiteType;

  const { title, background, divider, header_height } = header;

  const animekey = useAnimeKey({ ext: "divider-header" });

  return (
    <section
      key={animekey}
      className={`divider-header  divider ${background} ${header_height} ${
        isSinglePage ? "is-single-page" : ""
      }`}
    >
      <PageBreadcrumb isSinglePage={isSinglePage} post={post} />
      <Section className="is-gapless-top" disableColumns>
        <div className="columns is-multiline">
          {divider && divider.divider_on && (
            <div className="column is-12">
              <HeaderDivider data={divider} headline={title} />
            </div>
          )}
        </div>
      </Section>
    </section>
  );
};
