import { sanitize } from "isomorphic-dompurify";
import { isAvailable, LinkType } from "../../../utils/baseTypes";
import { Button } from "../../Global/Button";
import { Section } from "../../Global/Section";

export type BannerType = {
  title: string;
  content?: string;
  link?: LinkType;
};

interface BannerJobProps {
  banner: BannerType;
}

export const BannerJob = ({ banner }: BannerJobProps) => {
  const { title, content } = banner;

  const link = isAvailable<LinkType>(banner.link) ? banner.link : null;

  return (
    <Section
      className="banner-job is-gapless-top"
      disableColumns
      containerClassName="has-gradient-bg"
    >
      <div className="columns is-vcentered is-centered is-multiline job-banner-columns">
        <div className="column is-12 is-6-desktop has-text-centered-touch has-text-right-desktop job-banner-column-left">
          <span className="h3 mb-0">
            <strong
              dangerouslySetInnerHTML={{
                __html: sanitize(title),
              }}
            ></strong>
          </span>
        </div>
        <div className="column is-12 is-6-desktop job-banner-column-right">
          <div
            className="banner-job-content has-text-centered-touch"
            dangerouslySetInnerHTML={{
              __html: sanitize(content ?? "", {
                ADD_ATTR: ["target"],
              }),
            }}
          />
          {link && (
            <div className="button-wrapper mt-4">
              <Button
                type="primary"
                title={link.title}
                target={link.target}
                href={link.url}
              ></Button>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};
