import React from "react";
import {
  HeaderSeiteType,
  OptionsType,
  PageType,
} from "../../../utils/baseTypes";
import {
  PageBreadcrumb,
  PageContent,
  PageDivider,
  PageHeadline,
} from "./Common";
import { motion } from "framer-motion";
import { blur_opacity_variant } from "../../../utils/transition";
import { stagger_variant } from "../../../utils/transition";
import useAnimeKey from "../../../hooks/useAnimeKey";
import Media from "../../Media/Media";
import { VideoFrame } from "./VideoFrame";

interface TextMediaHeaderProps {
  post: PageType;
  isSinglePage: boolean;
  options: OptionsType;
}

export const TextMediaHeader = ({
  post,
  isSinglePage,
  options,
}: TextMediaHeaderProps) => {
  const header = post.acf?.header_seite as HeaderSeiteType;

  const {
    title,
    content,
    subline,
    background,
    divider,
    number_cols,
    hpos_class,
    header_height,
    tpos_class,
    media,
    media_size,
    mpos_class,
    title_size,
    video_oblique,
  } = header;

  const animekey = useAnimeKey({ ext: "cta-box-header" });
  const [disableRotate, setDisableRotate] = React.useState(false);

  return (
    <section
      className={`text-media-header section padding-top-0 divider ${background} ${header_height} ${
        isSinglePage ? "is-single-page" : ""
      }`}
    >
      <PageBreadcrumb post={post} isSinglePage={isSinglePage} />
      <div className="container">
        <motion.div
          initial="initial"
          whileInView={"animate"}
          variants={stagger_variant}
          viewport={{ once: true }}
          key={animekey}
          className={`columns is-multiline ${mpos_class}`}
        >
          <PageDivider background={background} divider={divider} />
          <div
            className={`column is-12 ${
              mpos_class === "is-row-reverse-desktop"
                ? "is-offset-1-desktop is-5-desktop"
                : "is-5-desktop"
            } `}
          >
            <PageHeadline
              subline={subline}
              title={title}
              tpos_class={tpos_class}
              title_size={title_size}
            />

            {content && (
              <PageContent
                hpos_class={hpos_class}
                number_cols={number_cols}
                tpos_class={tpos_class}
                content={content}
              />
            )}
          </div>
          <motion.div
            variants={blur_opacity_variant}
            className={`media-wrapper ${media.type} column is-12  ${
              mpos_class === "is-row-reverse-desktop"
                ? "is-6-desktop"
                : "is-offset-1-desktop is-6-desktop"
            }`}
          >
            <VideoFrame
              disableRotate={disableRotate}
              wrap={media.type === "video" && video_oblique}
            >
              <Media
                data={{
                  media: media,
                }}
                noSection
                //! below width and height only work for svg
                width={media_size === "media-is-small" ? 280 : "100%"}
                height={media_size === "media-is-small" ? 280 : "100%"}
                options={options}
                onPlay={() => setDisableRotate(true)}
                onPause={() => setDisableRotate(false)}
              />
            </VideoFrame>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};
