import { OptionsType, WikiType } from "../../utils/baseTypes";
import { Section } from "../Global/Section";
import { WikiPost } from "./WikiPost";
import { motion } from "framer-motion";

interface wikiRelatedPostsProps {
  relatedPosts: WikiType[] | null;
  options: OptionsType;
}
const transition_ = {
  ease: [0.455, 0.03, 0.515, 0.955],
};
const variants_cont = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      ...transition_,
      staggerChildren: 0.3,
    },
  },
};

export const WikiRelatedPosts = ({
  relatedPosts,
  options,
}: wikiRelatedPostsProps) => {
  const moreTerms = options.acf.more_terms;
  return (
    <>
      <Section
        id="wiki-related-posts"
        className="wiki more-posts has-grey-bg"
        disableColumns
      >
        <motion.div
          variants={variants_cont}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          className="columns is-multiline"
        >
          {moreTerms && (
            <div className="column is-12">
              <h2>
                <strong>{moreTerms}</strong>
              </h2>
            </div>
          )}
          {relatedPosts &&
            Array.isArray(relatedPosts) &&
            relatedPosts.map((wiki, i) => {
              return <WikiPost key={i} data={wiki} idx={i} />;
            })}
        </motion.div>
      </Section>
    </>
  );
};
