import Image from "next/image";
import {
  PageType,
  OptionsType,
  AuthorInsightType,
  AuthorInsight,
} from "../../utils/baseTypes";

import { sanitize } from "isomorphic-dompurify";

import { motion } from "framer-motion";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { HeaderInsights } from "../Insights/HeaderInsights";
import { stagger_variant } from "./HeroSlider/Hero";
import {
  blur_opacity_variant,
  defaultTransition,
} from "../../utils/transition";
import useAnimeKey from "../../hooks/useAnimeKey";

interface AutorHeaderProp {
  post: PageType;
  options: OptionsType;
  autorInsights: AuthorInsight;
}

const span_variants = {
  initial: {
    x: "-110%",
  },
  animate: {
    x: 0,
    transition: defaultTransition,
  },
};

const opacity_variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    transition: defaultTransition,
    opacity: 1,
  },
};
const image_variants = {
  initial: {
    opacity: 0,
    filter: "blur(10px)",
    x: "-30%",
  },
  animate: {
    transition: defaultTransition,
    opacity: 1,
    filter: "blur(0px)",
    x: 0,
  },
};

const AutorHeader = ({ post, autorInsights }: AutorHeaderProp) => {
  const autor_id = post.id;

  const animeKey = useAnimeKey({ ext: "autor-header" });
  const bg = post.acf?.header_autor?.background
    ? post.acf?.header_autor.background
    : "has-white-bg";

  return (
    <>
      <motion.section
        key={animeKey}
        variants={stagger_variant}
        initial={"initial"}
        whileInView={"animate"}
        viewport={{ once: true }}
        className={`autor-header ${bg}`}
      >
        <Breadcrumbs post={post} />
        <div className={`container`}>
          <motion.div className={`autor-single`}>
            <motion.div
              variants={image_variants}
              className="image-wrapper"
              viewport={{ once: false }}
            >
              {post.acf?.header_autor?.image && (
                <figure className="image is-164x164">
                  <Image
                    className="is-rounded"
                    src={post.acf?.header_autor?.image.url as string}
                    width={post.acf?.header_autor?.image.width}
                    height={post.acf?.header_autor?.image.height}
                    alt={post.acf?.header_autor?.image.alt}
                    data-title={post.acf?.header_autor?.image.title}
                    sizes="(max-width: 768px) 50vw, (max-width: 1023px) 25vw, (max-width: 1440px) 15vw, 10vw"
                  />
                </figure>
              )}
            </motion.div>
            <motion.div className="content">
              <motion.span className="upper-line mb-5">
                <motion.span variants={opacity_variants}>
                  Zur Person
                </motion.span>
                <motion.span className="animation-wrapper">
                  <motion.span variants={span_variants}></motion.span>
                </motion.span>
              </motion.span>
              <motion.h1 variants={blur_opacity_variant}>
                <strong
                  dangerouslySetInnerHTML={{
                    __html: sanitize(post.acf?.header_autor?.title ?? ""),
                  }}
                />
              </motion.h1>
              <motion.div
                variants={blur_opacity_variant}
                className="text"
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    post.acf?.header_autor ? post.acf?.header_autor?.text : "",
                    {
                      ADD_ATTR: ["target"],
                    }
                  ),
                }}
              />
              <motion.div className="topic">
                <motion.span variants={blur_opacity_variant}>
                  <strong>Themenschwerpunkte</strong>
                </motion.span>
                <motion.span
                  variants={blur_opacity_variant}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(post.acf?.header_autor?.topics ?? ""),
                  }}
                ></motion.span>
              </motion.div>
              <motion.div className="post-count mt-3">
                <motion.span variants={blur_opacity_variant}>
                  <strong>Anzahl der Beiträge</strong>
                </motion.span>
                <motion.span variants={blur_opacity_variant}>
                  {autorInsights.total_posts}
                </motion.span>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>

      {autorInsights.total_posts > 0 && (
        <HeaderInsights
          headline={`Alle Insights von ${post.acf?.header_autor?.title}`}
          data={autorInsights.posts}
          totalPosts={autorInsights.total_posts}
          autorName={post.acf?.header_autor?.title!}
          background={bg}
          autorId={autor_id}
        />
      )}
    </>
  );
};

export default AutorHeader;
