import Image from "next/image";
import { getImageCaption } from "../../hooks/getImageCaption";
import { ReactElement, useEffect, useState } from "react";
import { sanitize } from "isomorphic-dompurify";
import { ImageType } from "../../utils/baseTypes";
import { getImageType } from "../../utils/responsiveFile";
import useWindowSize from "../../hooks/useWindowSize";

const genCopyright = (
  key: number,
  text: string,
  url: string,
  className?: string
) => {
  if (!text && !url) {
    return;
  }

  if (text) {
    if (!url) {
      return (
        <p
          className={"caption-item " + className ?? ""}
          dangerouslySetInnerHTML={{
            __html: sanitize(text, {
              ADD_ATTR: ["target"],
            }),
          }}
          key={key}
        />
      );
    }

    return (
      <a href={url} className={className ?? ""} key={key}>
        <p
          className="caption-item"
          dangerouslySetInnerHTML={{
            __html: sanitize(text, {
              ADD_ATTR: ["target"],
            }),
          }}
        />
      </a>
    );
  }
};

interface MediaItemFillParentProps {
  image_desktop: ImageType | false;
  image_tablet: ImageType | false;
  image_mobile: ImageType | false;
  className?: string;
  objectFit?: "cover" | "contain";
}

export const MediaItemFillParent = ({
  image_desktop,
  image_tablet,
  image_mobile,
  className,
  objectFit = "cover",
}: MediaItemFillParentProps) => {
  const [emptycaption, setCaption] = useState<(ReactElement | undefined)[]>([]);

  const { width } = useWindowSize();
  const activeImage = getImageType({
    width: width,
    images: {
      image: image_desktop,
      image_tablet: image_tablet,
      image_mobile: image_mobile,
    },
  });

  useEffect(() => {
    if (image_desktop) {
      const fetchCaption = async () => {
        const caption = await getImageCaption(image_desktop.id);
        const captionJSX = [
          genCopyright(0, caption.acf.copyright, caption.acf["copyright-url"]),
          genCopyright(
            1,
            caption.acf.licence,
            caption.acf["licence-url"],
            "licence"
          ),
          genCopyright(
            2,
            caption.acf.source,
            caption.acf["source-url"],
            "source"
          ),
        ];
        setCaption(captionJSX);
      };
      fetchCaption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {activeImage && (
        <Image
          src={activeImage.url ?? ""}
          alt={activeImage.alt}
          data-title={activeImage.title}
          fill
          style={{ objectFit: objectFit, objectPosition: "center" }}
        />
      )}
      <span className={`image-caption ${className}`}>{emptycaption}</span>
    </>
  );
};
