import { motion } from "framer-motion";
import { sanitize } from "isomorphic-dompurify";
import React, { useState } from "react";
import { blur_opacity_variant } from "../../../utils/transition";
import {
  DividerType,
  LinkType,
  OptionsType,
  PageType,
  isAvailable,
} from "../../../utils/baseTypes";
import { Button, SimpleButton } from "../../Global/Button";
import Divider from "../../Divider/Divider";
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs";
import { ModalForm } from "../../Form/ModalForm";

interface PageHeadlineProps {
  title?: string;
  subline?: string;
  tpos_class: string;
  title_size?: "normal-size" | "big-size";
}

export const PageHeadline = ({
  subline,
  title,
  tpos_class,
  title_size = "normal-size",
}: PageHeadlineProps) => {
  return (
    <>
      {subline && (
        <motion.span
          variants={blur_opacity_variant}
          className={`overline ${tpos_class}`}
          dangerouslySetInnerHTML={{
            __html: sanitize(subline),
          }}
        />
      )}
      {title && (
        <motion.h1
          variants={blur_opacity_variant}
          className={`mb-4 ${tpos_class} ${title_size}`}
        >
          <strong
            dangerouslySetInnerHTML={{
              __html: sanitize(title),
            }}
          ></strong>
        </motion.h1>
      )}
    </>
  );
};

interface PageCTAProps {
  cta_type: "link" | "contact-form" | "berlinfahrten-form";
  link: "" | LinkType;
  button_label: string;
  options: OptionsType;
}

export const PageCTA = ({
  link,
  cta_type,
  button_label,
  options,
}: PageCTAProps) => {
  const form =
    cta_type === "berlinfahrten-form"
      ? "berlinfahrten"
      : cta_type === "contact-form"
      ? "contactform"
      : undefined;
  const link_ = isAvailable<LinkType>(link) ? link : null;
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {cta_type === "link" && link_ && link_.url && link_.title && (
        <motion.div variants={blur_opacity_variant}>
          <Button
            type="primary"
            title={link_.title}
            target={link_.target}
            href={link_.url}
            className="my-1_8"
          />
        </motion.div>
      )}
      {cta_type !== "link" && button_label && (
        <motion.div variants={blur_opacity_variant}>
          <SimpleButton
            text={button_label}
            className="my-1_8 btn is-primary"
            onClick={() => setModalOpen(true)}
          />
        </motion.div>
      )}
      <ModalForm
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        options={options}
        form={form}
      />
    </>
  );
};

interface PageContentProp {
  content?: string;
  number_cols: string;
  tpos_class: "has-text-centered" | "has-text-left";
  hpos_class: "headline-beside-text" | "headline-over-text";
}

export const PageContent = ({
  content,
  hpos_class,
  number_cols,
  tpos_class,
}: PageContentProp) => {
  return (
    <motion.div
      variants={blur_opacity_variant}
      className={`content ${
        hpos_class === "headline-over-text" ? number_cols : ""
      } ${tpos_class}`}
      dangerouslySetInnerHTML={{
        __html: sanitize(content ?? "", {
          ADD_ATTR: ["target"],
        }),
      }}
    />
  );
};

interface PageDividerProp {
  divider: DividerType;
  background: string;
}

export const PageDivider = ({ divider, background }: PageDividerProp) => {
  return (
    <>
      {divider && divider.divider_on && (
        <div className="column is-12">
          <Divider data={divider} background={background} />
        </div>
      )}
    </>
  );
};

interface PageBreadcrumbProp {
  post: PageType;
  isSinglePage: boolean;
  breadcrumbsClasses?: string;
}

export const PageBreadcrumb = ({
  post,
  isSinglePage,
  breadcrumbsClasses,
}: PageBreadcrumbProp) => {
  return post.slug !== "berlinfahrten" && !isSinglePage ? (
    <div className="pt-4 is-hidden-mobile">
      <Breadcrumbs post={post} breadcrumbsClasses={breadcrumbsClasses} />
    </div>
  ) : (
    <></>
  );
};
