import React from "react";
import AnimationPlayer from "../AnimationPlayer";
import { useScroll, useTransform, motion, easeIn } from "framer-motion";

interface ScrollDownProp {
  background: string;
  className?: string;
}

const BLACKCOND = ["has-white-bg", "has-grey-bg"];

export const ScrollDown = ({ background, className = "" }: ScrollDownProp) => {
  const animation = BLACKCOND.includes(background)
    ? "/black_scroll_icon.lottie"
    : "/white_scroll_icon.lottie";

  const { scrollY } = useScroll();

  const opacity = useTransform(scrollY, [0, 200], [1, 0]);

  return (
    <motion.div
      style={{ opacity: opacity }}
      className={`scroll-down ${className}`}
    >
      <AnimationPlayer
        url={animation}
        autoplay={true}
        loop={true}
        objectfit="contain"
      />
    </motion.div>
  );
};
