import { getImageCaption } from "../../hooks/getImageCaption";
import { ReactElement, useEffect, useState } from "react";
import Image from "next/image";
import { ImageType } from "../../utils/baseTypes";
import { getImageType } from "../../utils/responsiveFile";
import useWindowSize from "../../hooks/useWindowSize";

interface MediaItemProps {
  image_desktop: ImageType;
  image_tablet: ImageType | false;
  image_mobile: ImageType | false;
  objectFit?: "cover" | "contain";
  sizes?: string;
  quality?: number;
}

export const MediaItem = ({
  image_desktop,
  image_tablet,
  image_mobile,
  objectFit = "cover",
  sizes,
  quality,
}: MediaItemProps) => {
  const [emptycaption, setCaption] = useState<ReactElement | undefined>(
    undefined
  );

  const { width } = useWindowSize();

  const activeImage = getImageType({
    width: width,
    images: {
      image: image_desktop,
      image_tablet: image_tablet,
      image_mobile: image_mobile,
    },
  });

  useEffect(() => {
    const fetchCaption = async () => {
      const caption = await getImageCaption(image_desktop.id);
      const captionJSX = (
        <>
          {caption.acf?.text_1 && !caption.acf?.link_1 && (
            <p>{caption.acf.text_1}</p>
          )}
          {caption.acf?.text_1 && caption.acf?.link_1 && (
            <a
              href={caption.acf?.link_1}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>{caption.acf?.text_1}</p>
            </a>
          )}
        </>
      );
      setCaption(captionJSX);
    };
    fetchCaption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`image-wrapper`}>
      {activeImage &&
        (activeImage.mime_type !== "image/svg+xml" ? (
          <Image
            key={activeImage.title}
            src={activeImage.url ?? ""}
            alt={activeImage.alt}
            data-title={activeImage.title}
            height={activeImage.height}
            width={activeImage.width}
            style={{ objectFit: objectFit }}
            sizes={sizes}
            quality={quality}
          />
        ) : (
          <Image
            key={activeImage.title}
            alt={activeImage.alt}
            src={activeImage.url!}
            width={0}
            height={0}
            style={{ width: "auto", height: "100%" }}
            className="svg-image"
            sizes={sizes}
            quality={quality}
          />
        ))}
      {emptycaption && <span className="image-caption">{emptycaption}</span>}
    </div>
  );
};
