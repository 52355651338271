import React from "react";
export const VideoFrame = ({
  children,
  wrap = true,
  disableRotate = false,
}: {
  children: React.ReactNode;
  disableRotate?: boolean;
  wrap?: boolean;
}) => {
  return wrap ? (
    <div
      className={`media-frame-box-wrapper ${
        disableRotate ? "disable-rotate" : ""
      }`}
    >
      <div className="rotate">{children}</div>

      {/* below div's are for gradient corner on hover */}
      <div className="corner-div" />
      <div className="corner-div" />
    </div>
  ) : (
    <>{children}</>
  );
};
