import { Section } from "../Global/Section";
/*
import { ContentBlockerWrapper } from "@aleksundshantu.com/aunds-cookie-plugin-react";
*/

import {
  isAvailable,
  LayoutType,
  OptionsType,
  MediaType,
  DividerType,
  VideoType,
  ImageType,
} from "../../utils/baseTypes";
import Divider from "../Divider/Divider";
import Shadow from "../../Animtions/Shadow";
import { ContentBlockerWrapper } from "@aleksundshantu.com/aunds-cookie-plugin-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { setMediaJSX } from "../../utils/media";
import Image from "next/image";
import { fetchVideoThumbnail } from "../Global/Video";

interface MediaLayoutType extends LayoutType {
  media_size: "small" | "big";
}

export type MediaModuleType = {
  acf_fc_layout?: "media";
  media: MediaType;
  layout?: MediaLayoutType;
  divider?: DividerType;
};

interface MediaProps {
  data: MediaModuleType;
  options?: OptionsType;
  noSection?: boolean;
  height?: number | string;
  width?: number | string;
  type?: string;
  preserveAspectRatio?: boolean;
  objectFit?: "cover" | "contain";
  sizes?: string;
  index?: number;
  activeVideo?: number;
  setActiveVideo?: Dispatch<SetStateAction<number>>;
  quality?: number;
  onPlay?: Function;
  onPause?: Function;
}

const Media = ({
  data,
  options,
  noSection,
  objectFit = "contain",
  type,
  index,
  activeVideo,
  setActiveVideo,
  onPlay = () => {},
  onPause = () => {},
}: MediaProps) => {
  const mediaType = data.media.type;
  const layout = data.layout;
  const divider = data.divider;
  const mediaSize = layout ? layout.media_size : "";
  const isSmallMedia = mediaSize !== "small";
  const isMediaModule = !!data?.layout;
  const defaultImageSize = !isMediaModule
    ? "100vw"
    : isSmallMedia
    ? "(max-width: 768px) 100vw, (max-width: 1023px) 85vw, 60vw"
    : "(max-width: 768px) 92vw, 85vw";

  const video_ = isAvailable<VideoType>(data.media.video)
    ? data.media.video
    : undefined;
  const { video_type } = video_ ? video_ : { video_type: "" };
  const abstand = layout?.abstand === "default" ? "" : layout?.abstand || "";
  const bg = layout?.background ?? "";
  let columnSize = "";
  const [imageURL, setImageURL] = useState<string>("");

  const onlineVideo =
    video_?.externes_video || video_?.externes_video_iframe || "";
  const poster = isAvailable<ImageType>(data.media.image)
    ? data.media.image
    : undefined;
  const mediaJSX = setMediaJSX(
    data,
    activeVideo,
    setActiveVideo,
    index,
    objectFit,
    onPlay,
    onPause
  );

  useEffect(() => {
    poster?.url
      ? setImageURL(poster.url)
      : onlineVideo &&
        fetchVideoThumbnail(onlineVideo)
          .then((thumbnailDataUrl) => {
            setImageURL(thumbnailDataUrl);
          })
          .catch(() => {});

    //eslint-disable-next-line
  }, []);

  switch (mediaType) {
    case "video":
      if (video_) {
        const { video_size, video_type } = video_;

        if (video_type === "upload") {
          columnSize =
            type === "encyclopedia"
              ? "is-12-tablet is-8-widescreen"
              : video_size === "small"
              ? "is-10-widescreen is-8-fullhd is-centered"
              : "";
        } else {
          columnSize =
            type === "encyclopedia"
              ? "is-12-tablet is-8-widescreen"
              : mediaSize === "small"
              ? "is-10-widescreen is-8-fullhd is-centered"
              : "";
        }
      } else {
        columnSize =
          type === "encyclopedia"
            ? "is-12-tablet is-8-widescreen"
            : mediaSize === "small"
            ? "is-10-widescreen is-8-fullhd is-centered"
            : "";
      }
      break;
    default:
      columnSize =
        type === "encyclopedia"
          ? "is-12-tablet is-8-widescreen"
          : mediaSize === "small"
          ? "is-10-widescreen is-8-fullhd is-centered"
          : "";
      break;
  }

  if (noSection) {
    return (
      <>
        {mediaType === "video" ? (
          <div className="content-blocker-video-wrapper is-relative">
            {imageURL && (
              <Image
                className="thumbnail is-absolute"
                src={imageURL}
                alt=""
                width={200}
                height={200}
                loading="lazy"
              />
            )}
            <ContentBlockerWrapper
              type={video_type?.toLowerCase()}
              wpApiUrl={process.env.WP_API_URL!}
              className={`video-blocker ${
                imageURL ? "has-video-thumbnail" : null
              }`}
            >
              <Shadow wrap={layout?.shadow_animation}>{mediaJSX}</Shadow>
            </ContentBlockerWrapper>
          </div>
        ) : (
          <Shadow wrap={layout?.shadow_animation}>{mediaJSX}</Shadow>
        )}
      </>
    );
  } else {
    return (
      <Section
        id={layout?.id}
        className={`media ${
          type !== "encyclopedia" ? "divider" : ""
        } ${bg} ${abstand}`}
        columnsClassName="is-multiline is-centered"
      >
        {divider && divider.divider_on && (
          <div className="column is-12">
            <Divider data={divider} background={bg ?? ""} />
          </div>
        )}

        <div className={`column is-12-tablet ${columnSize}`}>
          {mediaType === "video" ? (
            <div className="content-blocker-video-wrapper is-relative">
              {imageURL && (
                <Image
                  className="thumbnail is-absolute"
                  src={imageURL}
                  alt=""
                  width={200}
                  height={200}
                  loading="lazy"
                />
              )}

              <ContentBlockerWrapper
                type={video_type.toLowerCase()}
                wpApiUrl={process.env.WP_API_URL!}
                className={`video-blocker ${
                  imageURL ? "has-video-thumbnail" : null
                }`}
              >
                <Shadow wrap={layout?.shadow_animation}>{mediaJSX}</Shadow>
              </ContentBlockerWrapper>
            </div>
          ) : (
            <Shadow wrap={layout?.shadow_animation}>{mediaJSX}</Shadow>
          )}
        </div>
      </Section>
    );
  }
};
export default Media;
