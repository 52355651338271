import { Dispatch, SetStateAction } from "react";
import AnimationPlayer from "../components/AnimationPlayer";
import { MediaItem } from "../components/Global/MediaItem";
import { InternalVideo, Video } from "../components/Global/Video";
import { MediaModuleType } from "../components/Media/Media";
import {
  AnimationType,
  ImageType,
  OptionsType,
  VideoType,
  isAvailable,
} from "./baseTypes";
import Image from "next/image";

const returnImageJSX = (
  img: ImageType | undefined,
  imgtab: ImageType | undefined,
  imgmob: ImageType | undefined,
  objectFit: "cover" | "contain" | undefined,
  sizes: string
) => {
  if (img) {
    return (
      <MediaItem
        image_desktop={img}
        image_tablet={imgtab as ImageType}
        image_mobile={imgmob as ImageType}
        objectFit={objectFit}
        sizes={sizes ?? "100vw"}
      />
    );
  }
};

const returnSvgJSX = (
  svg: ImageType | null,
  svg_animation: AnimationType | null,
  objectFit: "cover" | "contain"
) => {
  if (svg_animation) {
    return <AnimationPlayer objectfit={objectFit} url={svg_animation.url!} />;
  } else if (svg) {
    return (
      <Image
        alt={svg.alt}
        src={svg.url!}
        width={0}
        height={0}
        style={{ width: "auto", height: "100%" }}
        className="svg-image"
      />
    );
  }
};

const returnVideoForPlatform = (
  video: string | undefined,
  platformName: "iframe" | "vimeo" | "youtube" | undefined,
  index: number | undefined,
  activeVideo: number | undefined,
  setActiveVideo: Dispatch<SetStateAction<number>> | undefined,
  onPlay?: Function
) => {
  if (video) {
    return (
      <Video
        onlineVideo={video}
        activeVideo={activeVideo}
        index={index}
        setActiveVideo={setActiveVideo}
        videoType={platformName}
        onPlay={onPlay}
      />
    );
  }
};

const returnVideoJSX = (
  video_: VideoType | undefined,
  img: ImageType | undefined,
  index: number | undefined,
  activeVideo: number | undefined,
  setActiveVideo: Dispatch<SetStateAction<number>> | undefined,
  onPlay?: Function,
  onPause?: Function
) => {
  if (video_) {
    const {
      video_type,
      externes_video,
      externes_video_iframe,
      internes_video,
      autoplay,
      audio,
    } = video_;
    const poster = img;
    let video;

    switch (video_type) {
      case "iframe":
        video = externes_video_iframe;
        return video ? <Video onlineVideo={video} poster={poster} /> : null;
      case "Vimeo":
        return returnVideoForPlatform(
          externes_video,
          "vimeo",
          index,
          activeVideo,
          setActiveVideo,
          onPlay
        );
      case "Youtube":
        return returnVideoForPlatform(
          externes_video,
          "youtube",
          index,
          activeVideo,
          setActiveVideo,
          onPlay
        );
      case "upload":
        video = internes_video;
        return video ? (
          <InternalVideo
            video={video}
            poster={poster}
            autoplay={autoplay}
            enableAudioToggle={audio}
            onPlay={onPlay}
            onPause={onPause}
          />
        ) : null;
      default:
        return null;
    }
  }
};

export const setMediaJSX = (
  data: MediaModuleType,
  activeVideo: number | undefined,
  setActiveVideo: Dispatch<SetStateAction<number>> | undefined,
  index: number | undefined,
  objectFit: "cover" | "contain",
  onPlay?: Function,
  onPause?: Function
) => {
  const mediaType = data.media.type;
  const video_ = isAvailable<VideoType>(data.media.video)
    ? data.media.video
    : undefined;
  const img = isAvailable<ImageType>(data.media.image)
    ? data.media.image
    : undefined;
  const imgtab = isAvailable<ImageType>(data.media.image_tablet)
    ? data.media.image_tablet
    : undefined;
  const imgmob = isAvailable<ImageType>(data.media.image_mobile)
    ? data.media.image_mobile
    : undefined;

  const svg = isAvailable<ImageType>(data.media.svg) ? data.media.svg : null;
  const svg_animation = isAvailable<AnimationType>(data.media.svg_animation)
    ? data.media.svg_animation
    : null;

  let mediaJSX;
  switch (mediaType) {
    case "image":
      mediaJSX = returnImageJSX(img, imgtab, imgmob, objectFit, "100vw");
      break;
    case "svg":
      mediaJSX = returnSvgJSX(svg, svg_animation, objectFit);
      break;
    case "video":
      mediaJSX = returnVideoJSX(
        video_,
        img,
        index,
        activeVideo,
        setActiveVideo,
        onPlay,
        onPause
      );
      break;
    //todo need to remove this after backend move the animtion to svg type
    // @ts-ignore
    case "svg-animation":
      if (svg_animation) {
        mediaJSX = (
          <AnimationPlayer objectfit={objectFit} url={svg_animation.url!} />
        );
      }
      break;
    default:
      break;
  }

  return mediaJSX;
};
