import { useRouter } from "next/router";
import { useState, useEffect, DependencyList } from "react";

function useAnimeKey({ ext = "" }: { ext?: string }): string {
  const { query, locale } = useRouter();

  const dynamicRoute = query["dynamicRoute"]
    ? (query["dynamicRoute"] as string[]).join("/")
    : "/";

  const [animeKey, setAnimeKey] = useState<string>("");

  useEffect(() => {
    setAnimeKey(`${new Date().getTime()}-${ext}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicRoute, locale]);

  return animeKey;
}

export function useAnimeKeyAll({ ext = "" }: { ext?: string }): string {
  const { asPath, locale } = useRouter();

  const [animeKey, setAnimeKey] = useState<string>("");

  useEffect(() => {
    setAnimeKey(`${new Date().getTime()}-${ext}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, locale]);

  return animeKey;
}

export function useAnimeKeyWithDep({
  ext = "",
  deps = [],
}: {
  ext?: string;
  deps?: DependencyList;
}): string {
  const [animeKey, setAnimeKey] = useState<string>("");

  useEffect(() => {
    setAnimeKey(`${new Date().getTime()}-${ext}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return animeKey;
}

export default useAnimeKey;
