import React, { useState } from "react";
import { OptionsType, PageType } from "../../utils/baseTypes";

import BlogcastIcon from "../../images/icon-blogcast.svg";
import Image from "next/image";
import Link from "next/link";
import { Button } from "../Global/Button";
import { motion } from "framer-motion";
import { defaultTransition } from "../../utils/transition";
import useWindowSize from "../../hooks/useWindowSize";
import { ContentBlockerWrapper } from "@aleksundshantu.com/aunds-cookie-plugin-react";

interface InsightBlogCastType {
  post: PageType;
  options: OptionsType;
}

export const InsightBlogCast = ({ post, options }: InsightBlogCastType) => {
  const { blogcast, blogcast_iframe } = post.acf?.header_insights!;
  const [toggle, setToggle] = useState<boolean>(false);

  const { width } = useWindowSize();

  if (!blogcast) return null;

  const { blogcast: blogcastOptions } = options.acf;

  return (
    <motion.div
      variants={{
        open: {
          x: 0,
        },
        close: {
          x: width > 767 ? 528 : "calc(100vw - 65px)",
        },
      }}
      initial="close"
      animate={toggle ? "open" : "close"}
      transition={defaultTransition}
      className="insight-blog-cast"
    >
      <span className="action" onClick={() => setToggle((last) => !last)}>
        <BlogcastIcon width={37} height={37} />
      </span>

      <div className="streaming-wrapper">
        <div className="info-wrapper">
          <span className="title">{blogcastOptions.title}</span>
          <div className="icon-text">
            <span className="text">{blogcastOptions.subline}</span>
            <div className="icon">
              {blogcastOptions.logo && (
                <Image
                  src={blogcastOptions.logo.url ?? ""}
                  alt="logo"
                  fill
                  style={{ objectFit: "contain" }}
                />
              )}
            </div>
          </div>
          <div className="streaming-platforms">
            <span className="streaming-lable">
              {blogcastOptions.stream.text}
            </span>

            {blogcastOptions.stream.streaming.map((platform, key) => {
              return (
                <Link
                  prefetch={false}
                  key={key}
                  href={platform.link ? platform.link.url : "#"}
                  target={platform.link ? platform.link.target : "_blank"}
                  className="platform"
                  aria-label="streaming platform"
                >
                  {platform.image && (
                    <Image
                      src={platform.image.url ?? ""}
                      alt={platform.image.alt}
                      fill
                      style={{ objectFit: "contain" }}
                    />
                  )}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="iframe-wrapper">
          <ContentBlockerWrapper
            type={"Soundcloud"}
            wpApiUrl={process.env.WP_API_URL!}
            className="soundcloud-blocker"
          >
            <iframe
              src={`https://w.soundcloud.com/player/?visual=false&url=${blogcast_iframe}&show_artwork=true`}
              allowFullScreen
              className="sc-iframe"
            />
          </ContentBlockerWrapper>
        </div>
        <div className="link-wrapper">
          {blogcastOptions.stream.link && (
            <Button
              type="primary"
              href={blogcastOptions.stream.link.url}
              title={blogcastOptions.stream.link.title}
              target={blogcastOptions.stream.link.target}
            />
          )}
        </div>
      </div>
    </motion.div>
  );
};
