import { motion } from "framer-motion";

const Shadow = ({
  children,
  scale = 0.98,
  wrap = false,
}: {
  children: React.ReactNode;
  scale?: number;
  wrap?: boolean;
}) => {
  const variants = {
    hidden: {
      filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0))",
      scale: scale,
    },
    visible: {
      filter: "drop-shadow(0px 0px 30px rgba(38, 57, 77,0.6))",
      scale: 1,
    },
  };

  if (wrap)
    return (
      <motion.div
        className="hw-full"
        initial="hidden"
        whileInView="visible"
        variants={variants}
        transition={{
          ease: [0.455, 0.03, 0.515, 0.955],
          duration: 0.85,
        }}
      >
        {children}
      </motion.div>
    );

  return <>{children}</>;
};

export default Shadow;
