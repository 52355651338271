import { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { sanitize } from "isomorphic-dompurify";
import { DividerType } from "../../../utils/baseTypes";
import { unlinkWiki } from "../../../utils/unlinkWiki";
interface DividerProps {
  data: DividerType;
  headline?: string;
}
const transition = { duration: 1, ease: [0.6, 0.01, 0.05, 0.9] };
const textRight = {
  visible: {
    opacity: 1,
    x: "-50%",
    y: "-50%",
    left: "50%",
  },
  hidden: {
    opacity: 0,
    left: "40%",
  },
};
const textLeft = {
  visible: {
    opacity: 1,
    x: "-50%",
    y: "-50%",
    left: "50%",
  },
  hidden: {
    opacity: 0,
    left: "-40%",
    top: "50%",
  },
};

//#region TO animate divider
// const transition_ = {
//   ease: [0.455, 0.03, 0.515, 0.955],
//   duration: 0.85,
// };

// const bg_text_variants = {
//   hidden: {
//     scale: 0.8,
//     opacity: 0,
//   },
//   visible: {
//     scale: 1,
//     opacity: 1,
//     transition: transition_,
//   },
// };

// const line_variant = {
//   hidden: {
//     x: "-110%",
//   },
//   visible: {
//     x: 0,
//     transition: {
//       ease: [0.455, 0.03, 0.515, 0.955],
//       duration: 1.3,
//     },
//   },
// };

//#endregion

const HeaderDivider = ({ data, headline }: DividerProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const dividerRowRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(48);
  const [dividerRowHeight, setdividerRowHeight] = useState<number>(0);

  useEffect(() => {
    document.fonts.ready.then(function () {
      if (headerRef?.current) {
        setHeight(headerRef?.current.clientHeight);
      }
      if (dividerRowRef?.current) {
        setdividerRowHeight(dividerRowRef?.current.clientHeight);
      }
    });
  }, []);

  return (
    <motion.div
      className="divider animation-active "
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <div className="columns h-in-divider is-hidden-desktop">
        <div className="is-offset-6-desktop p-0 column hide-overflow has-text-right">
          <motion.h1
            initial={{
              y: "100%",
            }}
            whileInView={{
              y: 0,
            }}
            transition={{
              ease: [0.455, 0.03, 0.515, 0.955],
              delay: 1,
              duration: 0.7,
            }}
            viewport={{ once: true }}
            className="m-0"
          >
            <strong
              dangerouslySetInnerHTML={{
                __html: sanitize(headline ?? ""),
              }}
            ></strong>
          </motion.h1>
        </div>
      </div>

      <div ref={dividerRowRef} className="divider-row columns is-multiline">
        <div
          className="columns h-in-divider is-hidden-touch"
          data-height-row={dividerRowHeight}
          data-height={height}
          ref={headerRef}
          style={{
            top: dividerRowHeight / 2 - height,
          }}
        >
          <div className="is-offset-6-desktop p-0 column hide-overflow has-text-right">
            <motion.h1
              initial={{
                y: "100%",
              }}
              whileInView={{
                y: 0,
              }}
              transition={{
                ease: [0.455, 0.03, 0.515, 0.955],
                delay: 1,
                duration: 0.7,
              }}
              viewport={{ once: true }}
              className="m-0"
            >
              <strong
                dangerouslySetInnerHTML={{
                  __html: sanitize(headline ?? ""),
                }}
              ></strong>
            </motion.h1>
          </div>
        </div>

        <div className="divider-body column is-full">
          <p
            className="divider-content p-0"
            dangerouslySetInnerHTML={{
              __html: sanitize(unlinkWiki(data?.subline)),
            }}
          />
          <motion.p
            variants={textLeft}
            transition={{ ...transition }}
            className="divider-background-title upper-part"
            aria-disabled="true"
            dangerouslySetInnerHTML={{
              __html: sanitize(unlinkWiki(data?.bg_text)),
            }}
          />
          <div className="line divider-content has-white-bg" />
          <motion.p
            variants={textRight}
            transition={{ ...transition }}
            className="divider-background-title lower-part"
            aria-disabled="true"
            dangerouslySetInnerHTML={{
              __html: sanitize(unlinkWiki(data?.bg_text)),
            }}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default HeaderDivider;
