import { motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import React, {
  AnchorHTMLAttributes,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import { loader_variant } from "../../utils/transition";
import { LoadMoreIcon } from "./LoadMoreIcon";
import { useAnimeKeyAll } from "../../hooks/useAnimeKey";
import { PaginationPageType } from "../../utils/baseTypes";

const MotionLink = motion(Link);

interface LoadMoreProp {
  animationState: "loading" | "idle" | "hide" | "onhover" | "endhover";
  setAnimationState: Dispatch<
    SetStateAction<"loading" | "idle" | "hide" | "onhover" | "endhover">
  >;
  pageType: PaginationPageType;
  background: string;
  page: number;
  callback?: Function;
}

export const LoadMore = ({
  background,
  pageType,
  page,
  animationState,
  setAnimationState,
  callback = () => {},
}: LoadMoreProp) => {
  const { query } = useRouter();

  const dynamicRoute = query["dynamicRoute"]
    ? (query["dynamicRoute"] as string[])
    : [];

  const path = dynamicRoute.join("/");

  const animeKey = useAnimeKeyAll({ ext: "load-more" });
  const eventAnimationState = useRef<
    "loading" | "idle" | "hide" | "onhover" | "endhover"
  >("idle");
  useEffect(() => {
    eventAnimationState.current = animationState;
  }, [animationState]);

  return (
    <MotionLink
      key={animeKey}
      exit={"hide"}
      onMouseEnter={() => {
        if (eventAnimationState.current !== "loading")
          setAnimationState("onhover");
      }}
      onMouseLeave={() => {
        if (eventAnimationState.current !== "loading")
          setAnimationState("endhover");
      }}
      animate={animationState}
      className="is-flex"
      shallow
      variants={loader_variant}
      href={`${path}?pt=${pageType}&page=${page}`}
      as={`${path}/?page=${page}&pt=${pageType}`}
      scroll={false}
      onClick={() => callback()}
      aria-label="load-more"
    >
      <LoadMoreIcon background={background} />
    </MotionLink>
  );
};

interface LoadMoreButtonProp extends AnchorHTMLAttributes<HTMLAnchorElement> {
  pageType: PaginationPageType;
  page: number;
}
export const LoadMoreButton = ({
  pageType,
  page,
  ...props
}: LoadMoreButtonProp) => {
  const { query } = useRouter();

  const dynamicRoute = query["dynamicRoute"]
    ? (query["dynamicRoute"] as string[])
    : [];

  const path = dynamicRoute.join("/");

  return (
    <Link
      {...props}
      className={`btn is-primary`}
      shallow
      href={`${path}?pt=${pageType}&page=${page}`}
      as={`${path}/?page=${page}&pt=${pageType}`}
      scroll={false}
      prefetch={false}
    >
      <div />
      <div />
      {/* //todo add label */}
      LOAD MORE
      <div />
      <div />
    </Link>
  );
};
