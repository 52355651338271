import { DotLottiePlayer } from "@johanaarstein/dotlottie-player";
import { AnimationType, ImageType, OptionsType } from "../../utils/baseTypes";
import AnimationPlayer from "../AnimationPlayer";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import ArrowIcon from "../../images/arrow.svg";
import useWindowSize from "../../hooks/useWindowSize";
import { sanitize } from "isomorphic-dompurify";
import { unlinkWiki } from "../../utils/unlinkWiki";
import { useRouter } from "next/router";
import { getRelativePathFromLink } from "../../utils/url";

interface SvgIconProps {
  svg?: ImageType;
  svg_animation?: AnimationType | false;
  width?: number | string;
  height?: number | string;
  priority?: boolean;
  objectFit?: "cover" | "contain";
  options?: OptionsType;
}

export const SvgIcon = ({
  svg_animation,
  svg,
  priority = false,
  objectFit = "cover",
  options,
}: SvgIconProps) => {
  const [animation, setAnimation] = useState<DotLottiePlayer | null>(null);

  if (svg_animation)
    return (
      <>
        <AnimationPlayer
          AnimationRef={setAnimation}
          objectfit={objectFit}
          url={svg_animation.url!}
        />
        <AnimationToolTip animation={animation} options={options} />
      </>
    );

  if (svg)
    return (
      <Image
        alt={svg.alt}
        src={svg.url!}
        width={0}
        height={0}
        style={{ width: "auto", height: "100%" }}
        className="svg-image"
        priority={priority}
      />
    );

  return <></>;
};

interface AnimationToolTipProps {
  animation: DotLottiePlayer | null;
  options?: OptionsType;
}
const AnimationToolTip = ({ animation, options }: AnimationToolTipProps) => {
  const animation_tooltips = options ? options?.acf.animation_tooltip : [];

  const [show, setShow] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const toolTipRef = useRef<HTMLDivElement>(null);
  const { width: SreenWidth } = useWindowSize();

  const router = useRouter();

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      e.preventDefault();
      const target = e.currentTarget as HTMLElement;
      const id = target.id;
      const tooltip = animation_tooltips.find((t) => t.id === id);
      if (tooltip) {
        tooltip.link
          ? router.push(getRelativePathFromLink(tooltip.link.url))
          : null;

        // tooltip.link
        //   ? window.open(getRelativePathFromLink(tooltip.link.url), "_blank")
        //   : null;
      }
    };

    const onMouseEnter = (e: MouseEvent) => {
      e.preventDefault();
      const target = e.currentTarget as HTMLElement;
      const id = target.id;
      const tooltip = animation_tooltips.find((t) => t.id === id);
      if (tooltip) {
        setText(tooltip.text);
      }

      const parentSvg = target.closest("svg") as SVGSVGElement;

      if (toolTipRef.current) {
        const { top, left } = parentSvg.getBoundingClientRect();
        const { x, y } = target.getBoundingClientRect();
        const { width, height } = toolTipRef.current.getBoundingClientRect();
        const X = x - left - width;
        const Y = y - top - height;

        toolTipRef.current.style.top = `${Y}px`;
        toolTipRef.current.style.left = `${X}px`;
      }

      setShow(true);
    };

    const onMouseLeave = (e: MouseEvent) => {
      e.preventDefault();
      setShow(false);
    };

    const elements: HTMLElement[] = [];
    if (animation) {
      const shadow = animation.shadowRoot;

      for (let i = 0; i < animation_tooltips.length; i++) {
        const animation_tooltip = animation_tooltips[i];
        const { id } = animation_tooltip;
        const element = shadow?.getElementById(id) as HTMLElement;
        if (element) {
          elements.push(element);
          element.style.cursor = "pointer";
          element.addEventListener("click", onClick);
          element.addEventListener("mouseenter", onMouseEnter);
          element.addEventListener("mouseleave", onMouseLeave);
        }
      }
    }

    return () => {
      for (let i = 0; i < elements.length; i++) {
        const dot = elements[i];
        if (dot) {
          dot.removeEventListener("click", onClick);
          dot.removeEventListener("mouseenter", onMouseEnter);
          dot.removeEventListener("mouseleave", onMouseLeave);
        }
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation, SreenWidth]);
  return (
    <>
      <div
        ref={toolTipRef}
        className="animation-tooltip"
        style={{ visibility: show ? "visible" : "hidden" }}
      >
        <div className="tooltip-wrapper">
          <span>{unlinkWiki(text)}</span>
          <ArrowIcon width={30} height={14} />
        </div>
      </div>
    </>
  );
};
