import { PageType } from "./baseTypes";

interface ICheckSinglePage {
  page: PageType;
}

export const checkSinglePage = ({ page }: ICheckSinglePage) => {
  try {
    let isSinglePage = false;
    if (page.acf?.header_seite) {
      isSinglePage = page.acf?.header_seite.single_page_mode;
    } else if (page.acf?.header_beitrag) {
      isSinglePage = page.acf?.header_beitrag.single_page_mode;
    } else if (page.acf?.header_autor) {
      isSinglePage = page.acf?.header_autor.single_page_mode;
    } else if (page.acf?.header_cta_person) {
      isSinglePage = page.acf?.header_cta_person.single_page_mode;
    } else if (page.acf?.header_joinus) {
      isSinglePage = page.acf?.header_joinus.single_page_mode;
    } else if (page.acf?.wiki_header) {
      isSinglePage = page.acf?.wiki_header.single_page_mode;
    } else if (page.acf?.header_insights) {
      isSinglePage = page.acf?.header_insights.single_page_mode;
    } else if (page.acf?.header_project) {
      isSinglePage = page.acf?.header_project.single_page_mode;
    } else if (page.acf?.header_team) {
      isSinglePage = page.acf?.header_team.single_page_mode;
    }

    return typeof isSinglePage === "boolean" ? isSinglePage : false;
  } catch (e) {
    console.error("Error in checkSinglePage.ts", e);
    return false;
  }
};
