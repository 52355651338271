import { useEffect, useRef, useState } from "react";
import { ImageType, VideoFileType } from "../../../../utils/baseTypes";
import { MediaItem } from "../../../Global/MediaItem";
import { MediaItemFillParent } from "../../../Global/MediaItemFillParent";

interface VideoProps {
  activeVideo: VideoFileType;
  poster: {
    desktop: ImageType | false;
    tablet: ImageType | false;
    mobile: ImageType | false;
  };
}

const Video = ({ activeVideo, poster }: VideoProps) => {
  const video = useRef<HTMLVideoElement>(null);

  const [videoLoads, setVideoLoads] = useState(true);

  useEffect(() => {
    if (!video || !video.current) return;
    video.current.load();
  }, []);

  return (
    <>
      {videoLoads && poster.desktop && (
        <MediaItemFillParent
          image_desktop={poster.desktop}
          image_tablet={poster.tablet}
          image_mobile={poster.mobile}
        />
      )}
      {
        <video
          ref={video}
          muted
          loop
          preload="none"
          playsInline
          onCanPlay={() => {
            video.current?.play();
            setVideoLoads(false);
          }}
        >
          <source src={activeVideo.url ?? ""} type={activeVideo.mime_type} />
          Your browser does not support HTML5 video.
        </video>
      }
    </>
  );
};
export default Video;
