import { Dispatch, SetStateAction, useState } from "react";
import { sanitize } from "isomorphic-dompurify";
import {
  isAvailable,
  LinkType,
  CTABoxType,
  ImageType,
  AnimationType,
} from "../../utils/baseTypes";

import Image from "next/image";
import { unlinkWiki } from "../../utils/unlinkWiki";
import { Button, SimpleButton } from "../Global/Button";
import AnimationPlayer from "../AnimationPlayer";

export const CTABox = ({
  cta,
  background,
  setModalOpen,
}: {
  cta: CTABoxType;
  background?: string;
  setModalOpen?: Dispatch<SetStateAction<boolean>>;
}) => {
  const cta_type = cta.cta_link ? cta.cta_link.cta_type : "email";

  const image = isAvailable<ImageType>(cta.image) ? cta.image : null;
  const cta_link = cta.cta_link ? cta.cta_link.link : cta.link;
  const link = isAvailable<LinkType>(cta_link) ? cta_link : null;

  let mediaJSX;
  switch (cta.image_type) {
    case "image":
      mediaJSX = image ? (
        <div className="media-wrapper">
          <Image
            className="cta-image"
            src={image.url!}
            alt={image.alt}
            data-title={image.title}
            fill
          />
        </div>
      ) : (
        <></>
      );
      break;
    case "svg":
      const svg = isAvailable<ImageType>(cta.svg) ? cta.svg : null;
      const animation = isAvailable<AnimationType>(cta.svg_animation)
        ? cta.svg_animation
        : null;

      if (animation) {
        mediaJSX = animation ? (
          <div className="media-wrapper">
            <AnimationPlayer url={animation.url!} objectfit="contain" />
          </div>
        ) : (
          <></>
        );
        break;
      }
      mediaJSX = svg ? (
        <div className="media-wrapper">
          <object data={svg.url!} title={svg.title} />
        </div>
      ) : (
        <></>
      );
      break;
    default:
      mediaJSX = image ? (
        <div className="media-wrapper">
          <Image
            className="cta-image"
            src={image.url!}
            alt={image.alt}
            data-title={image.title}
            fill
          />
        </div>
      ) : (
        <></>
      );
      break;
  }

  return (
    <>
      <div className="is-flex is-flex-direction-column is-align-items-center">
        <div className={`cta-box ${background ? background : "has-white-bg"}`}>
          {mediaJSX}
          <div className="cta-info">
            <span
              className="subline"
              dangerouslySetInnerHTML={{
                __html: sanitize(cta.subline),
              }}
            ></span>
            <div
              className={`cta-content`}
              dangerouslySetInnerHTML={{
                __html: sanitize(unlinkWiki(cta.cta_content), {
                  ADD_ATTR: ["target"],
                }),
              }}
            />
            {link && cta_type === "email" && (
              <Button
                type="primary"
                title={link.title}
                target={link.target}
                href={link.url}
              />
            )}

            {cta_type === "link-form" && (
              <SimpleButton
                type="button"
                className="btn is-primary"
                text="Kontaktformular"
                onClick={() =>
                  setModalOpen ? setModalOpen((last) => !last) : null
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
