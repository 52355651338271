import { useEffect, useRef, useState } from "react";
import { formStateProp, form_animation, form_transition } from "./Form";
import useWindowSize from "../../hooks/useWindowSize";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { AnimatePresence, motion } from "framer-motion";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Link from "next/link";

import useUpdateEffect from "../../hooks/useUpdateEffect";

import { SimpleButton } from "../Global/Button";

interface ContactFormValues {
  contact_person: string;
  mdb: string;
  email: string;
  telefon: string;
  policy_accept: boolean;
  Hcaptcha: string;
}

export const BerlinfahrtenForm = ({
  setformState,
  setPrevHeight,
  options,
}: formStateProp) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<ContactFormValues>();

  const { width } = useWindowSize();

  const [policy, setPolicy] = useState<boolean>(false);
  const {
    hint,
    privacy_policy,
    title,
    button,
    email,
    name_contact_person,
    name_mdb,
    phone_number,
  } = options.acf.berlinfahrten_form;
  const errorMessages = options.acf.error_messages;
  useEffect(() => {
    if (setPrevHeight) {
      if (formRef.current) {
        setPrevHeight(formRef.current.offsetHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const onSubmit = async (data: ContactFormValues) => {
    setformState("loading");
    const form = {
      firstName: data.mdb,
      lastName: data.contact_person,
      email: data.email,
      phone: data.telefon,
      "g-recaptcha-response": data.Hcaptcha,
    };

    const { status } = await emailjs
      .send(
        "service_vqo6uec",
        "template_h7fp6o3",
        form,
        process.env.EMAILJS_PUBLIC_KEY
      )
      .catch((status) => status);

    if (status !== 200) {
      setformState("error");
      return;
    }

    setformState("success");
  };
  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setPrevHeight) {
      if (formRef.current) {
        setPrevHeight(formRef.current.offsetHeight);
      }
    }

    register("Hcaptcha", { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setPrevHeight) {
      if (formRef.current) {
        setPrevHeight(formRef.current.offsetHeight);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy]);

  return (
    <motion.div
      variants={form_animation}
      initial={"hidden"}
      whileInView={"visible"}
      viewport={{ once: true }}
      exit={"exit"}
      className="form-state column is-12"
    >
      <motion.form
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
          transition: {
            delay: 0.6,
            ...form_transition,
          },
        }}
        viewport={{ once: true }}
        onSubmit={handleSubmit(onSubmit)}
        className="form-contact"
      >
        <AnimatePresence>
          <motion.div layout ref={formRef} className="columns is-multiline m-0">
            <div className="column is-12 px-2 py-5">
              <span className="is-inline-block form-title">{title}</span>
            </div>
            <div className="column is-12  px-2 py-4">
              <TextBox
                name="mdb"
                type="text"
                label={name_mdb}
                register={register}
                options={{
                  required: true,
                }}
              />

              <span className="error-message ">
                {errors["mdb"]?.type === "required"
                  ? `${name_mdb} ${errorMessages.required}`
                  : ""}
                {errors["mdb"]?.message ? errors["mdb"]?.message : ""}
              </span>
            </div>
            <div className="column is-12 px-2 py-4">
              <TextBox
                name="contact_person"
                type="text"
                label={name_contact_person}
                register={register}
                options={{
                  required: true,
                }}
              />
              <span className="error-message ">
                {errors["contact_person"]?.type === "required"
                  ? `${name_contact_person} ${errorMessages.required}`
                  : ""}
                {errors["contact_person"]?.message
                  ? errors["contact_person"]?.message
                  : ""}
              </span>
            </div>
            <div className="column is-12 px-2 py-4">
              <TextBox
                name="email"
                type="text"
                label={email}
                register={register}
                options={{
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: errorMessages.email,
                  },
                }}
              />

              <span className="error-message ">
                {errors["email"]?.type === "required"
                  ? `${email} ${errorMessages.required}`
                  : ""}
                {errors["email"]?.message ? errors["email"]?.message : ""}
              </span>
            </div>
            <div className="column is-12  px-2 py-4">
              <TextBox
                name="telefon"
                type="text"
                label={phone_number}
                register={register}
              />
            </div>

            <div className="column is-12 px-2 py-5">
              <div className="policy-check">
                <div>
                  <label className="input-checkbox">
                    <input
                      onClick={() => {
                        setPolicy((last) => !last);
                      }}
                      type="checkbox"
                      {...register("policy_accept", { required: true })}
                    />
                    <span
                      dangerouslySetInnerHTML={{ __html: privacy_policy }}
                    />
                  </label>
                  <span className="error-message ">
                    {errors["policy_accept"]?.type === "required"
                      ? errorMessages.privacy_policy
                      : ""}
                  </span>
                </div>

                <label className="mini-text">{hint}</label>
              </div>
            </div>

            {policy && (
              <motion.div layout className="column is-12  px-2 py-5">
                <HCaptcha
                  sitekey="7192fd03-5dd8-4051-9794-78aab551ec74"
                  onVerify={(token) => setValue("Hcaptcha", token)}
                />

                <span className="error-message ">
                  {errors["Hcaptcha"]?.type === "required"
                    ? `${errors["Hcaptcha"]?.ref?.name} ${errorMessages.required}`
                    : ""}
                </span>
              </motion.div>
            )}

            <div className="column is-12 px-2 py-5">
              <SimpleButton
                type="submit"
                className="btn is-primary"
                text={button}
                disabled={isSubmitting}
              />
            </div>
          </motion.div>
        </AnimatePresence>
      </motion.form>
    </motion.div>
  );
};

import { InputHTMLAttributes } from "react";
import { UseFormRegister, Path, RegisterOptions } from "react-hook-form";

interface TextBoxProp extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<ContactFormValues>;
  label: string;
  register: UseFormRegister<ContactFormValues>;
  options?: RegisterOptions<ContactFormValues>;
}

export const TextBox = ({
  label,
  register,
  options,
  name,
  ...props
}: TextBoxProp) => {
  return (
    <div className="input-group">
      <input
        {...register(name, options)}
        //! hacky way to implement material input
        placeholder=" "
        autoComplete="false"
        {...props}
      />
      <span className="highlight"></span>
      <span className="bar"></span>
      <label htmlFor={name} className="">
        {label}
        {options?.required ? " *" : ""}
      </label>
    </div>
  );
};
