import React from "react";
import {
  HeaderSeiteType,
  OptionsType,
  PageType,
} from "../../../utils/baseTypes";
import useAnimeKey from "../../../hooks/useAnimeKey";
import { motion } from "framer-motion";
import { Section } from "../../Global/Section";
import { stagger_variant } from "../../../utils/transition";
import {
  PageBreadcrumb,
  PageDivider,
  PageHeadline,
  PageCTA,
  PageContent,
} from "./Common";
import { PortraitSlider } from "../../PortraitSlider/PortraitSlider";

interface PortraitSliderHeaderProps {
  post: PageType;
  isSinglePage: boolean;
  portraitSliderPortfolio: PageType[];
  options: OptionsType;
}

export const PortraitSliderHeader = ({
  isSinglePage,
  portraitSliderPortfolio,
  post,
  options,
}: PortraitSliderHeaderProps) => {
  const header = post.acf?.header_seite as HeaderSeiteType;

  const {
    title,
    content,
    subline,
    background,
    divider,
    number_cols,
    hpos_class,
    header_height,
    tpos_class,
    link,
    cta_type,
    button_label,
    portraitslider_type,
    portraitslider,

    title_size,
  } = header;

  const animeKey = useAnimeKey({ ext: "portrait-slider-header" });

  //! changing size of column
  let columnSize: string;
  switch (hpos_class) {
    case "headline-beside-text":
      columnSize = "is-12 is-6-desktop";
      break;
    case "headline-over-text":
      columnSize = "is-12";
      break;
    default:
      columnSize = "is-12";
      break;
  }

  return (
    <motion.section
      initial="initial"
      whileInView={"animate"}
      variants={stagger_variant}
      viewport={{ once: true }}
      key={animeKey}
      className={`portrait-slider-header divider ${background} ${header_height} ${
        isSinglePage ? "is-single-page" : ""
      }`}
    >
      <PageBreadcrumb isSinglePage={isSinglePage} post={post} />
      <Section className="padding-top-0 pb-3" disableColumns>
        <motion.div className="columns is-multiline">
          <PageDivider background={background} divider={divider} />
          <div className="column is-12">
            <div className="columns is-multiline headline-wrapper">
              <div
                className={`column ${columnSize} ${
                  columnSize === "is-12" ? " pb-0" : ""
                }`}
              >
                <PageHeadline
                  subline={subline}
                  title={title}
                  tpos_class={tpos_class}
                  title_size={title_size}
                />

                {portraitslider_type !== "portfolio-slider" && (
                  <PageCTA
                    link={link}
                    cta_type={cta_type}
                    button_label={button_label}
                    options={options}
                  />
                )}
              </div>
              {content && (
                <div
                  className={`column ${columnSize} ${
                    columnSize === "is-12" ? " pt-0" : ""
                  }`}
                >
                  <PageContent
                    hpos_class={hpos_class}
                    number_cols={number_cols}
                    tpos_class={tpos_class}
                    content={content}
                  />
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </Section>

      <PortraitSlider
        data={portraitslider ? portraitslider : []}
        sectionClassName={
          portraitslider_type === "portfolio-slider"
            ? "is-gapless-top-bottom"
            : "is-gapless-top"
        }
        background={background}
        sliderType={portraitslider_type}
        enableContainer={true}
        portraitSliderPortfolio={portraitSliderPortfolio}
      />
    </motion.section>
  );
};
