import { sanitize } from "isomorphic-dompurify";
import dayjs from "dayjs";
import { motion } from "framer-motion";

import { AuthorInsightType } from "../../utils/baseTypes";
import { Section } from "../Global/Section";
import Arrow from "../../images/arrow_dark.svg";
import { FrameWrapper } from "../Global/FrameWrapper";
import { getRelativePathFromLink } from "../../utils/url";
import { unlinkWiki } from "../../utils/unlinkWiki";
import { useEffect, useState } from "react";
import Link from "next/link";
import { LoadMore } from "../Global/LoadMore";
import { useRouter } from "next/router";
import { getDataArrayGeneric } from "../../utils/getData";
import useUpdateEffect from "../../hooks/useUpdateEffect";
const transition_ = {
  ease: [0.455, 0.03, 0.515, 0.955],
  duration: 1,
};

const variant = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: transition_,
  },
};

interface HeaderInsightsProps {
  headline: string;
  autorName: string;
  data: AuthorInsightType[];
  background: string;
  totalPosts: number;
  autorId: number;
}
export const HeaderInsights = ({
  headline,
  data,
  autorName,
  background,
  totalPosts,
  autorId,
}: HeaderInsightsProps) => {
  const [headerInsights, setHeaderInsights] = useState<AuthorInsightType[]>([]);
  useEffect(() => {
    setHeaderInsights(data);
  }, [data]);

  const posts_to_load = 10;
  const hasPostsToLoad = headerInsights.length < totalPosts;

  const [animationState, setAnimationState] = useState<
    "loading" | "idle" | "hide" | "onhover" | "endhover"
  >("idle");

  const { query, locale, defaultLocale } = useRouter();
  const page = query["page"] ? Number(query["page"]) : 1;
  const pagetype = query["pt"] ? query["pt"] : null;

  const loadMore = async () => {
    setAnimationState("loading");

    const loaded = headerInsights.length;

    const isToShow =
      Number(posts_to_load) * (page - 1) === loaded
        ? null
        : Number(posts_to_load) * (page - 1);

    const url = `/aunds/author-insights/${autorId}?per_page=${
      isToShow ? isToShow : posts_to_load
    }&page=${(loaded / posts_to_load + 1).toFixed(0)}`;
    const { pageData } = await getDataArrayGeneric<AuthorInsightType[]>(
      url,
      locale,
      defaultLocale
    );

    setHeaderInsights((last) => last.concat(pageData));

    setAnimationState("idle");
  };

  useUpdateEffect(() => {
    if (pagetype === null || pagetype === "author-insights") {
      loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Section className="header-autor-insights has-white-bg" disableColumns>
      <div className="columns">
        <div className="column is-12 is-7-desktop hide-overflow">
          <motion.h2
            variants={variant}
            initial="hidden"
            whileInView="visible"
            viewport={{
              once: true,
            }}
          >
            <strong
              dangerouslySetInnerHTML={{
                __html: sanitize(headline),
              }}
            />
          </motion.h2>
        </div>
      </div>
      <div className="columns is-multiline">
        {headerInsights.map((insight, i) => {
          return (
            <motion.div
              variants={variant}
              initial="hidden"
              whileInView="visible"
              viewport={{
                once: true,
              }}
              key={i}
              className="column is-12"
            >
              <InsightCard insight={insight} autorName={autorName} />
            </motion.div>
          );
        })}
      </div>
      <div className="columns">
        <motion.div className="loadmore-wrapper column is-12-tablet">
          {hasPostsToLoad && (
            <LoadMore
              background={background}
              pageType="author-insights"
              page={page + 1}
              animationState={animationState}
              setAnimationState={setAnimationState}
            />
          )}
        </motion.div>
      </div>
    </Section>
  );
};

const InsightCard = ({
  insight,
  autorName,
}: {
  insight: AuthorInsightType;
  autorName: string;
}) => {
  const { date } = insight;
  const { title, show_updated_datum, updated_datum } = insight;

  const link = {
    title: "",
    url: getRelativePathFromLink(insight.link),
    target: "_self",
  };

  const categories = insight.categories.filter(
    (x) => typeof x.category_url === "string"
  );

  return (
    <FrameWrapper link={link} wrapLink>
      <div className="insight-wrapper">
        <div className="columns is-multiline">
          <div className="column is-12 is-9-desktop insight-content-wrapper">
            <div className="insight-info">
              <div className="categories">
                {categories.map((category, i) => {
                  return (
                    <Link
                      prefetch={false}
                      href={getRelativePathFromLink(category.category_url)}
                      key={i}
                      className="category"
                    >
                      {category.category_name}
                    </Link>
                  );
                })}
              </div>
              <span
                className="title"
                dangerouslySetInnerHTML={{
                  __html: sanitize(unlinkWiki(title)),
                }}
              />
            </div>
            <div className="autor-info">
              <span>
                {show_updated_datum
                  ? updated_datum
                  : dayjs(date).format("DD.MM.YYYY")}
                &nbsp;|&nbsp;
                {autorName}
              </span>
            </div>
          </div>
          <div className="column is-12 is-offset-2-desktop is-1-desktop is-flex is-align-items-flex-end">
            <div className="arrow">
              <Arrow width={22.06} height={14.58} />
            </div>
          </div>
        </div>
      </div>
    </FrameWrapper>
  );
};
