import { getUrl } from "./url";
import { PageType, WikiType } from "./baseTypes";
import { fetchWithRetry } from "./fetchWithRetry";

export async function getData(
  path: string,
  lang?: string,
  defaultLang?: string
): Promise<PageType | WikiType> {
  const pageRes = await fetchWithRetry(`${getUrl(lang, defaultLang)}${path}`);
  return (await pageRes.json()) as Promise<PageType | WikiType>;
}

export interface DataArray {
  code: number;
  pageData: PageType[];
  headers: Headers;
}

export async function getDataArray(
  path: string,
  lang?: string,
  defaultLang?: string
): Promise<DataArray> {
  const pageRes = await fetchWithRetry(`${getUrl(lang, defaultLang)}${path}`);
  const pageData = await pageRes.json();
  return { pageData, code: pageRes.status, headers: pageRes.headers };
  // return (await pageRes.json().catch(() => false)) as Promise<PageType[]>;
}

export async function getDataArrayGeneric<T>(
  path: string,
  lang?: string,
  defaultLang?: string
): Promise<{
  pageData: T;
  code: number;
  headers: Headers;
}> {
  const pageRes = await fetchWithRetry(`${getUrl(lang, defaultLang)}${path}`);
  const pageData = await pageRes.json();
  return { pageData, code: pageRes.status, headers: pageRes.headers } as {
    pageData: T;
    code: number;
    headers: Headers;
  };
}
