import { Section } from "../Global/Section";
import { getRelativePathFromLink } from "../../utils/url";
import {
  PageType,
  OptionsType,
  EncyclopediaCategoryType,
  WikiType,
  HeaderWikiType,
  AzFilterPostType,
} from "../../utils/baseTypes";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import Divider from "../Divider/Divider";
import Link from "next/link";
import { sanitize } from "isomorphic-dompurify";
import { Filter } from "../Wiki/Filter";
import { useAppContext } from "../../context/AppContext";
import { useRouter } from "next/router";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { WikiCtaBox } from "../Wiki/WikiCtaBox";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import SwiperType from "swiper/swiper";
import Arrow from "../../images/arrow_dark.svg";
import useWindowSize from "../../hooks/useWindowSize";

interface WikiHeaderProps {
  post: WikiType;
  options: OptionsType;
  breadcrumbData?: PageType;
  wikiCategories: EncyclopediaCategoryType[];
  wikiInfo: AzFilterPostType;
  wikiFilterLetters: string[];
}

const transition_ = {
  ease: [0.455, 0.03, 0.515, 0.955],
  duration: 0.85,
};

const variant_left = {
  visible: {
    opacity: 1,
    x: 0,
    transition: transition_,
  },
  hidden: {
    opacity: 0,
    x: -100,
  },
};

const variant_right = {
  visible: {
    opacity: 1,
    x: 0,
    transition: transition_,
  },
  hidden: {
    opacity: 0,
    x: 100,
    zIndex: 2,
    transition: transition_,
  },
};

const WikiHeader = ({
  post,
  breadcrumbData,
  wikiCategories,
  wikiFilterLetters,
}: WikiHeaderProps) => {
  const categories: EncyclopediaCategoryType[] = [];
  const hasCta = post.acf?.wiki_header?.has_cta;
  const background = post.acf?.wiki_header?.background;
  const abstand = post.acf?.wiki_header?.abstand;

  post["encyclopedia-category"]?.map((category) => {
    const result = wikiCategories.find((c) => c.id === category);
    if (result) {
      categories.push(result);
    }
  });
  const router = useRouter();
  const { setAzFilter, setInitialLoading } = useAppContext();
  const mainCategories = wikiCategories.filter((c) => c.parent === 0);
  const header: HeaderWikiType | undefined = post.acf?.wiki_header;
  const ref = useRef<HTMLDivElement>(null);
  const [fixedTop, setFixedTop] = useState(false);
  const [hidden, setHidden] = useState(true);

  const { width } = useWindowSize();
  // const isTouch = width < 1024;
  // const isSlider = width < 1216;
  const isWideScreen = width < 1216;
  const letterHandler = (letter: string) => {};

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (scrollValue) => {
    if (ref.current) {
      const { top, height } = ref.current.getBoundingClientRect();
      if (top && top <= 150 && !isWideScreen) {
        ref.current.style.height = `${height}px`;
        setFixedTop(true);
      } else if (!isWideScreen) {
        setFixedTop(false);
      }
    }

    if (scrollValue > 200) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  });

  useEffect(() => {
    if (isWideScreen) {
      setFixedTop(true);
    } else {
      setFixedTop(false);
    }
  }, [isWideScreen]);

  return (
    <Section
      className={`wiki-header divider  ${hasCta ? "cta" : ""} ${
        background ?? ""
      } ${abstand ?? "is-gapless-top"}`}
      columnsClassName={`is-multiline ${hasCta ? " " : "is-centered"}`}
      isClipped={false}
    >
      <div className="column is-12 ">
        {breadcrumbData && <Breadcrumbs post={breadcrumbData} />}
      </div>

      <div
        style={{
          position: "absolute",
          visibility: hidden ? "hidden" : "visible",
        }}
        className="column is-12 wiki-filters"
      >
        <Filter
          letters={wikiFilterLetters}
          letterHandler={letterHandler}
          shallowRouting={false}
        />
      </div>

      <div className="column is-12">
        {mainCategories && (
          <>
            {!isWideScreen && (
              <div className="wiki-categories is-flex is-flex-wrap-wrap is-justify-content-center mb-3">
                <CategoryLinks categories={mainCategories} />
              </div>
            )}

            {isWideScreen && (
              <div className="wiki-categories mb-3">
                <CategorySlider categories={mainCategories} />
              </div>
            )}
          </>
        )}
      </div>

      <div className="column is-12">
        {categories[0]?.acf.divider && (
          <Divider data={categories[0]?.acf.divider} />
        )}
      </div>
      <motion.div
        variants={variant_left}
        initial="hidden"
        whileInView={"visible"}
        animate={"touch"}
        viewport={{ once: true }}
        className={
          hasCta
            ? "column is-12-tablet is-8-widescreen"
            : "column is-12-tablet is-8-widescreen max-width"
        }
      >
        <h1>
          <strong
            dangerouslySetInnerHTML={{
              __html: sanitize(post.title.rendered),
            }}
          ></strong>
        </h1>
        {header?.title_subline && (
          <span
            className="subline"
            dangerouslySetInnerHTML={{
              __html: sanitize(header.title_subline, {
                ADD_ATTR: ["target"],
              }),
            }}
          />
        )}

        {header?.content && (
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: sanitize(header.content, {
                ADD_ATTR: ["target"],
              }),
            }}
          />
        )}
      </motion.div>
      {hasCta && !isWideScreen && (
        <motion.div
          variants={variant_right}
          initial="hidden"
          whileInView={"visible"}
          viewport={{ once: true }}
          ref={ref}
          className={`column is-offset-8-tablet is-offset-0-desktop is-4-tablet is-4-desktop column-cta`}
        >
          <WikiCtaBox post={post} fixedTop={fixedTop} />
        </motion.div>
      )}

      {hasCta && isWideScreen && <WikiCtaBox post={post} fixedTop={fixedTop} />}
    </Section>
  );
};

const CategoryLinks = ({
  categories,
}: {
  categories: EncyclopediaCategoryType[];
}) => {
  return (
    <>
      {categories.map((category, i) => {
        return (
          <Link
            className="wiki-category"
            href={getRelativePathFromLink(category.link)}
            key={i}
            prefetch={false}
          >
            {category.name}
          </Link>
        );
      })}
    </>
  );
};

const CategorySlider = ({
  categories,
}: {
  categories: EncyclopediaCategoryType[];
}) => {
  const swiperRef = useRef<SwiperType>();

  const [cat, setCat] = useState<EncyclopediaCategoryType[]>([]);

  useEffect(() => {
    setCat(categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="categories-slider">
      <motion.button
        initial={{
          rotate: 180,
        }}
        whileTap={{ scale: 0.8 }}
        className="prev"
        aria-label="prev"
        onClick={() => {
          swiperRef.current?.slidePrev();
        }}
      >
        <Arrow width={21} height={12} />
      </motion.button>
      <Swiper
        slidesPerView={3}
        speed={700}
        modules={[Navigation]}
        loop
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          485: {
            slidesPerView: 2,
          },
          690: {
            slidesPerView: 3,
          },
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {cat.map((category) => {
          return (
            <SwiperSlide key={category.id}>
              <Link
                className="wiki-category"
                href={getRelativePathFromLink(category.link)}
                prefetch={false}
              >
                {category.name}
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <motion.button
        className="next"
        aria-label="next"
        whileTap={{ scale: 0.8 }}
        onClick={() => {
          swiperRef.current?.slideNext();
        }}
      >
        <Arrow width={21} height={12} />
      </motion.button>
    </div>
  );
};

export default WikiHeader;
