import React from "react";
import {
  HeaderSeiteType,
  OptionsType,
  PageType,
} from "../../../../utils/baseTypes";

import { HeroFullScreen } from "./HeroFullScreen";
import { HeroSizeScreen } from "./HeroSizeScreen";
import { ScrollDown } from "../../../ScrollDown/ScrollDown";

export interface HeroPageProps {
  post: PageType;
  isSinglePage: boolean;
  options: OptionsType;
}

const HeroPage = ({ post, isSinglePage, options }: HeroPageProps) => {
  const header = post.acf?.header_seite as HeaderSeiteType;
  const { media_width, background } = header;

  let HeroJSX = <></>;

  switch (media_width) {
    case "media-is-full":
      HeroJSX = (
        <HeroFullScreen
          isSinglePage={isSinglePage}
          post={post}
          options={options}
        />
      );
      break;
    case "media-is-half":
      HeroJSX = (
        <HeroSizeScreen
          isSinglePage={isSinglePage}
          post={post}
          options={options}
          size="half"
        />
      );
      break;
    case "media-is-three-quarters":
      HeroJSX = (
        <HeroSizeScreen
          isSinglePage={isSinglePage}
          post={post}
          options={options}
          size="three-quarters"
        />
      );
      break;
    default:
      HeroJSX = (
        <HeroFullScreen
          isSinglePage={isSinglePage}
          post={post}
          options={options}
        />
      );
      break;
  }

  return (
    <>
      {HeroJSX}
      <ScrollDown background={background} />
    </>
  );
};

export default HeroPage;
